import { observer } from 'mobx-react-lite';
import { userStore } from 'Stores/UserStore';
import Notification from 'components/Sidebar/components/Notification/Notification';
import { type NotificationsI } from 'Interfaces/userInterfaces';
import styles from './NotificationsTable.module.scss';

export const NotificationsTable = observer(() => {
  return (
    <div
      className={styles.table__bg}
      onClick={() => userStore.setIsOpenNotifications(false)}
    >
      <div className={styles.table} onClick={e => e.stopPropagation()}>
        <p
          className={styles.table__close}
          onClick={() => userStore.setIsOpenNotifications(false)}
        >
          Закрыть
        </p>
        <div className={styles.table__body}>
          {userStore.user_header.notifications?.length ? (
            userStore.user_header.notifications.map(
              (data: NotificationsI, index) => (
                <Notification {...data} key={index} />
              ),
            )
          ) : (
            <h2 className={styles.not__notification}>
              Здесь пока нет уведомлений
            </h2>
          )}
        </div>
      </div>
    </div>
  );
});
