import { observer } from 'mobx-react-lite';
import { type DocumentationI } from 'Interfaces/coursesInterfaces';
import MustCart from '../MustCart/MustCart';
import styles from './MustDeveloper.module.scss';
import MustPython from './img/MustPython.png';
import MustJavaScript from './img/MustJavaScript.png';

const MustDeveloper = observer((data: DocumentationI) => {
  return (
    <div className={styles.must__developer}>
      <h2>{data.name}</h2>
      <div className={styles.must__information}>
        <img
          src={
            data.name === 'Основные обязанности Backend разработчиков'
              ? MustPython
              : MustJavaScript
          }
          alt=""
        />
        <div className={styles.must__list__cart}>
          <MustCart
            id="1"
            name="Серверная разработка"
            description="Разработка и поддержка серверной части веб-приложений с использованием языков программирования, таких как Python"
          />
          <MustCart
            id="2"
            name="Базы данных и приложения"
            description="Создание и оптимизации баз данных для хранения и обработки данных, необходимых для работы приложения"
          />
          <MustCart
            id="3"
            name="API для взаимодействия клиент-сервер"
            description="Разработка"
          />
          <MustCart
            id="4"
            name="Безопасность и масштабирование серверов"
            description="Обеспечение безопасности и масштабируемости серверной инфраструктуры"
          />
          <MustCart
            id="5"
            name="Оптимизация сервера"
            description="Тестирование и отладка серверной части приложения для обеспечения ее корректной работы и высокой производительности"
          />
        </div>
      </div>
    </div>
  );
});

export default MustDeveloper;
