import { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { userStore } from 'Stores/index';
import RatingUser from './components/RatingUser/RatingUser';
import styles from './Rating.module.scss';

const Rating = observer(() => {
  const [message, setMessage] = useState('');
  const [name, setName] = useState('');

  useEffect(() => {
    document.title = 'Рейтинг';
    window.scrollTo(0, 0);
    userStore.getTopUsers();
    setName('');
  }, []);

  const list = ['ИМЯ', 'РАНГ', 'ОПЫТ'];

  return (
    <section className={`${styles.rating__page} container`}>
      <div className={styles.user}>
        <div className={styles.rating__header}>
          <div className={styles.block}>
            <p className={styles.header__item_place}>МЕСТО</p>
            {list.map((item, index) => (
              <p key={index} className={styles.header__item}>
                {item}
              </p>
            ))}
          </div>

          <div className={styles.panel__search}>
            <button
              className={styles.panel__search__button}
              onClick={() => {
                userStore.searchTopUsers(message);
                setMessage('');
              }}
            />
          </div>
        </div>
        <hr />
        <div className={styles.rating__list}>
          <input
            onChange={e => setName(e.target.value)}
            value={name}
            className={styles.input}
            placeholder="Поиск"
          />
          <>
            {name === ''
              ? userStore.rating.top_users.map((rating_user, index) => (
                <RatingUser key={index} userdata={rating_user} />
              ))
              : userStore.rating.top_users
                .filter(
                  user =>
                    user.first_name.includes(name) ||
                      user.last_name.includes(name) ||
                      user.place.toString().includes(name),
                  )
                .map((rating_user, index) => (
                  <RatingUser key={index} userdata={rating_user} />
                ))}
          </>
        </div>
      </div>
    </section>
  );
});

export default Rating;
