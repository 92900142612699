import { observer } from 'mobx-react-lite';
import { type TableCourseI } from 'Interfaces/userInterfaces';
import userLogo from 'style/img/UserIcon.svg';
import { BASE_URL } from 'consturl';
import styles from './TableItem.module.scss';

export const TableItem = observer((data: TableCourseI) => {
  return (
    <div className={styles.table__user}>
      <div className={styles.table__user_avatar_block}>
        <img
          src={data.avatar_photo ? BASE_URL + data.avatar_photo : userLogo}
          className={styles.table__user_avatar}
          alt={'avatar'}
        />
        <p className={styles.table__user_name}>
          {`${data.last_name} ${data.first_name}`}
        </p>
      </div>
      <p className={styles.table__user_text}>{data.task_count}</p>
      <p className={styles.table__user_text}>{data.completed_task_count}</p>
      <p className={styles.table__user_text}>{data.completion_percentage}</p>
      <p className={styles.table__user_text}>{data.total_time} секунд</p>
      <p className={styles.table__user_text}>{data.email}</p>
    </div>
  );
});
