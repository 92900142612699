import { observer } from 'mobx-react-lite';
import { type ModalPlatformCartI } from 'Interfaces/helpInterfaces';
import styles from './ModalPlatformCart.module.scss';

const ModalPlatformCart = observer(
  ({ img, text, link }: ModalPlatformCartI) => {
    return (
      <a href={link} className={styles.link__cart__platform}>
        <div className={styles.modal__platform__cart}>
          <img src={img} alt="" />
          <p>{text}</p>
        </div>
      </a>
    );
  },
);

export default ModalPlatformCart;
