import styles from './HeadCourse.module.scss';

export const HeadCourse = () => {
  return (
    <div className={styles.table__head}>
      <p className={styles.table__head_text}>Пользователь</p>
      <p className={styles.table__head_text}>Всего</p>
      <p className={styles.table__head_text}>Пройдено</p>
      <p className={styles.table__head_text}>Решений</p>
      <p className={styles.table__head_text}>Пройдено за</p>
      <p className={styles.table__head_text}>Почта</p>
    </div>
  );
};
