import remove_icon from 'style/img/remove-icon.svg';
import plus from 'style/img/add-lesson-icon-light.svg';
import { type FC } from 'react';
import styles from './createCouseBtn.module.scss';

interface CreateCourseBtnI {
  type: 'normal' | 'delete' | 'add';
  number?: number;
  onClick?: () => void;
  selected?: boolean;
}

export const CreateCourseBtn: FC<CreateCourseBtnI> = ({
  type,
  number,
  onClick,
  selected,
}) => {
  return (
    <div onClick={onClick}>
      {type === 'normal' ? (
        <div className={selected ? styles.cyrcle_selecteed : styles.cyrcle}>
          {number}
        </div>
      ) : type === 'delete' ? (
        <div className={styles.cyrcle_red}>
          <img src={remove_icon} />
        </div>
      ) : type === 'add' ? (
        <div className={styles.cyrcle_white}>
          <img src={plus} />
        </div>
      ) : (
        ''
      )}
    </div>
  );
};
