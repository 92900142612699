import { type ChangeEvent, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { Button, Spinner } from 'components/index';
import { userStore, coursesStore } from 'Stores/index';
import cn from 'classnames';
import style from './testPage.module.scss';
import {
  CheckboxChoice,
  RadioChoice,
  ErrorComponent,
  SuccessComponent,
} from './components/index';

export const TestPage = observer(() => {
  const { course_id, task_id, user_id } = useParams();
  const navigate = useNavigate();
  const { courseTask } = coursesStore;
  const [selectedChoices, setSelectedChoices] = useState<number[]>([]);
  const { message } = coursesStore.consoleOutput;

  const [elapsedTime, setElapsedTime] = useState<number>(0);

  useEffect(() => {
    const startTimeStr = localStorage.getItem(`startTime-${task_id}`);
    if (!startTimeStr) {
      const now = Date.now().toString();
      localStorage.setItem(`startTime-${task_id}`, now);
    }

    const intervalId = setInterval(() => {
      const startTimeStr = localStorage.getItem(`startTime-${task_id}`);
      if (startTimeStr) {
        const startTime = parseInt(startTimeStr, 10);
        const now = Date.now();
        setElapsedTime(Math.floor((now - startTime) / 1000));
      }
    }, 1000);
    if (
      userStore.user_header.is_staff &&
      window.location.href.includes('user')
    ) {
      coursesStore.getUserTask(Number(user_id), task_id);
      coursesStore.setCompletedTask(null);
    } else {
      coursesStore.getTask(task_id);
    }
    coursesStore.setConsoleOutput({});
    document.title = 'Теория';
    window.scrollTo(0, 0);
  }, []);

  const handleChoiceChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { type, value, checked } = event.target;
    if (type === 'radio') {
      setSelectedChoices([Number(value)]);
    } else if (type === 'checkbox') {
      if (checked) {
        setSelectedChoices(prev => [...prev, Number(value)]);
      } else {
        setSelectedChoices(prev =>
          prev.filter(choice => Number(choice) != Number(value)),
        );
      }
    }
  };

  const handleSubmit = (event: ChangeEvent<HTMLFormElement>) => {
    event.preventDefault();
    const startTimeStr = localStorage.getItem(`startTime-${task_id}`);
    if (startTimeStr) {
      const startTime = parseInt(startTimeStr, 10);
      const now = Date.now();
      const totalTime = Math.floor((now - startTime) / 1000);
      coursesStore.createAnswer(selectedChoices, Number(task_id), totalTime);
      localStorage.removeItem(`startTime-${task_id}`);
    } else {
      const totalTime = 0;
      coursesStore.createAnswer(selectedChoices, Number(task_id), totalTime);
    }
  };

  const nextTaskRedirect = () => {
    coursesStore.completedTask = null;
    if (message) {
      if (courseTask.next_task_type === 'Practice') {
        navigate(`/course/${course_id}/task/${courseTask.next_task_id}`);
      } else if (courseTask.next_task_type === 'Theory') {
        navigate(`/course/${course_id}/testing/${courseTask.next_task_id}`);
      }
    } else {
      navigate(`/course/${course_id}/testing/${task_id}`);
    }
  };

  return (
    <section className={style.compiler}>
      <div className={style.compiler__content__wrapper}>
        <div className={style.compiler__task__theory}>
          <h2 className={style.compiler__task__theory__title}>
            {coursesStore.courseTask.name_task}
          </h2>
          {coursesStore.courseTask.theory && (
            <div
              className={style.compiler__task__theory__text}
              dangerouslySetInnerHTML={{
                __html: coursesStore.courseTask.theory,
              }}
            />
          )}
        </div>

        <form className={style.compiler__wrapper} onSubmit={handleSubmit}>
          {coursesStore.courseTask?.type_choices?.toLowerCase() === 'radio' ? (
            <p className={style.compiler__theory__subtitle}>
              Выберете правильный ответ
            </p>
          ) : (
            <p className={style.compiler__theory__subtitle}>
              Выберете правильный ответ (их может быть несколько):
            </p>
          )}
          {coursesStore.courseTask?.type_choices?.toLowerCase() === 'radio' ? (
            <>
              {coursesStore.courseTask?.choices?.map(item => (
                <RadioChoice
                  key={item.id}
                  value={Number(item.id)}
                  onChange={handleChoiceChange}
                  choice={item.title}
                />
              ))}
            </>
          ) : (
            <>
              {coursesStore.courseTask?.choices?.map(item => (
                <CheckboxChoice
                  choice={item.title}
                  key={item.id}
                  name={`choice-${item.id}`}
                  value={Number(item.id)}
                  onChange={handleChoiceChange}
                />
              ))}
            </>
          )}

          <div className={style.compiler__console__buttons__wrapper}>
            <div className={style.btn__block}>
              <Button
                type="submit"
                text={
                  coursesStore.loading ? (
                    <Spinner />
                  ) : coursesStore.completedTask ||
                    coursesStore.completedTask == null ? (
                    'Проверить'
                  ) : (
                    'Пройдите тест заново'
                  )
                }
                className={cn(style.compiler__btn, {
                  [style.compiler__btn_error]:
                    coursesStore.completedTask === false,
                })}
              />
              <Button
                type="submit"
                text="Следующий"
                onClick={nextTaskRedirect}
                className={style.compiler__btn_next}
              />
            </div>

            {coursesStore.completedTask == null ? (
              ''
            ) : coursesStore.completedTask ? (
              <SuccessComponent />
            ) : (
              <ErrorComponent />
            )}
          </div>
        </form>
      </div>
    </section>
  );
});
