import { observer } from 'mobx-react-lite';
import { coursesStore } from 'Stores/CourseStore';
import { type StatisticsUserCourseI } from 'Interfaces/coursesInterfaces';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import UserCartStatCourse from 'pages/StatisticsCourse/components/UserCartStatCourse/UserCartStatCourse';
import { BASE_URL } from '../../consturl';
import styles from './StatisticsCourse.module.scss';

const StatisticsCourse = observer(() => {
  const { course_id } = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    coursesStore.getCourseStatistics(course_id);
  }, []);
  return (
    <div className={styles.statistics__course}>
      <div className={styles.logo__block}>
        <p
          onClick={() => navigate('/course/' + course_id)}
          className={styles.btn__back}
        >
          НАЗАД
        </p>
        <div className={styles.information__course}>
          <img
            src={BASE_URL + coursesStore.statisticsCourse.preview_image}
            alt=""
          />
          <p>{coursesStore.statisticsCourse.course_name}</p>
        </div>
      </div>
      <div className={styles.table__block}>
        <div className={styles.header__table}>
          <p> Пользователь </p>
          <p> Выполнено </p>
          <p> Проценты </p>
          <p> Почта </p>
        </div>
        <div className={styles.user__stat__list}>
          {coursesStore.statisticsCourse.users.map(
            (data: StatisticsUserCourseI, index) => {
              return <UserCartStatCourse {...data} key={index} />;
            },
          )}
        </div>
      </div>
      <div className={styles.footer__menu}>
        <p onClick={() => coursesStore.getStatisticsCourse(course_id)}>
          Скачать ответы
        </p>
      </div>
    </div>
  );
});

export default StatisticsCourse;
