import { observer } from 'mobx-react-lite';
import { coursesStore } from 'Stores/CourseStore';
import { type DocumentationI } from 'Interfaces/coursesInterfaces';
import { JavaScriptMockAddInf } from '../../Mock/JavaScriptMockData';
import { PythonMockDataAddInf } from '../../Mock/PuthonMockData';
import Arrow from './img/Next.svg';
import styles from './SlideAdditionalInformation.module.scss';

const SlideAdditionalInformation = observer((data: DocumentationI) => {
  return (
    <div className={styles.slide__additional__information}>
      <div className={styles.arrow__menu}>
        <div
          className={styles.arrow__block__left}
          onClick={
            coursesStore.numberSliderPython !== 0
              ? () =>
                coursesStore.setNumberSliderPython(
                  coursesStore.numberSliderPython - 1,
                  )
              : () => coursesStore.setNumberSliderPython(2)
          }
        >
          <img src={Arrow} alt="" />
        </div>
        <div
          className={styles.arrow__block__right}
          onClick={
            coursesStore.numberSliderPython < 2
              ? () =>
                coursesStore.setNumberSliderPython(
                  coursesStore.numberSliderPython + 1,
                  )
              : () => coursesStore.setNumberSliderPython(0)
          }
        >
          <img src={Arrow} alt="" />
        </div>
      </div>
      <div className={styles.slide__information}>
        {data.name !== 'Python' ? (
          <>
            <h3>
              {JavaScriptMockAddInf[coursesStore.numberSliderPython].name}
            </h3>
            <p>
              {
                JavaScriptMockAddInf[coursesStore.numberSliderPython]
                  .description
              }
            </p>
            <div className={styles.settings__list}>
              {JavaScriptMockAddInf[
                coursesStore.numberSliderPython
              ].settings.map((data: DocumentationI, index) => {
                return (
                  <div className={styles.setting__information} key={index}>
                    <h4>{data.name}</h4>
                    <p>{data.description}</p>
                  </div>
                );
              })}
            </div>
          </>
        ) : (
          <>
            <h3>
              {PythonMockDataAddInf[coursesStore.numberSliderPython].name}
            </h3>
            <p>
              {
                PythonMockDataAddInf[coursesStore.numberSliderPython]
                  .description
              }
            </p>
            <div className={styles.settings__list}>
              {PythonMockDataAddInf[
                coursesStore.numberSliderPython
              ].settings.map((data: DocumentationI, index) => {
                return (
                  <div className={styles.setting__information} key={index}>
                    <h4>{data.name}</h4>
                    <p>{data.description}</p>
                  </div>
                );
              })}
            </div>
          </>
        )}
      </div>
    </div>
  );
});

export default SlideAdditionalInformation;
