import { observer } from 'mobx-react-lite';
import NavigateHeader from 'components/Header/components/NavigateHeader/NavigateHeader';
import TitleHeader from 'components/Header/components/TitleHeader/TitleHeader';
import UserHeader from 'components/Header/components/UserHeader/UserHeader';
import styles from './Header.module.scss';

const Header = observer(() => {
  return (
    <div className={styles.header}>
      <div className="container">
        <div className={styles.header__wrapper}>
          <TitleHeader />
          <NavigateHeader />
          <UserHeader />
        </div>
      </div>
    </div>
  );
});

export default Header;
