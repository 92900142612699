import Select from 'react-select';
import { createCoursesStore } from 'Stores/CreateCourseStore';
import { observer } from 'mobx-react-lite';
import { Task_types } from 'Interfaces/userInterfaces';
import { complexities, languages, task_types } from '../../components/mokData';
import { ChoicesBlock } from './components/ChoicesBlock/ChoicesBlock';
import { PracticeTexteteaBlock } from './components/PracticeTexteteaBlock/PracticeTexteteaBlock';
import styles from './DataMenu.module.scss';

export const DataMenu = observer(() => {
  const { createdCourse, selectString, selectLesson, selectTask } =
    createCoursesStore;

  const task =
    createdCourse.lesson[selectLesson]?.task_string[selectString]?.task[
      selectTask
    ];

  return (
    <div className={styles.options}>
      <Select
        options={task_types}
        placeholder={'Тип задачи'}
        onChange={item => {
          if (item) {
            createCoursesStore.handleChangeType(item.value);
          }
        }}
        value={
          createCoursesStore.getCurrentTaskType()
            ? {
              label: createCoursesStore.getCurrentTaskType()!,
              value: createCoursesStore.getCurrentTaskType()!,
              }
            : null
        }
        className={styles.one}
      />

      <input
        placeholder={'Название задания'}
        value={task?.name_task || ''}
        onChange={event => createCoursesStore.changeTaskName(event)}
        className={styles.title}
      />

      <input
        placeholder={'XP'}
        type="number"
        value={task?.xp || ''}
        onChange={event => createCoursesStore.changeTaskXp(event)}
        className={styles.title}
      />

      <Select
        options={complexities}
        placeholder={'Сложность'}
        onChange={item => {
          if (item) {
            createCoursesStore.handleChangeComplexity(item.value);
          }
        }}
        value={
          createCoursesStore.getCurrentComplexity()
            ? {
              label: createCoursesStore.getCurrentComplexity()!,
              value: createCoursesStore.getCurrentComplexity()!,
              }
            : null
        }
        className={styles.four}
      />

      <Select
        options={languages}
        placeholder={'Язык программирования'}
        onChange={item => {
          if (item) {
            createCoursesStore.handleChangeLanguage(item.value);
          }
        }}
        value={
          createCoursesStore.getCurrentLanguage()
            ? {
              label: createCoursesStore.getCurrentLanguage()!,
              value: createCoursesStore.getCurrentLanguage()!,
              }
            : null
        }
        className={styles.five}
      />
      <textarea
        className={styles.textarea}
        value={task?.theory || ''}
        onChange={event => createCoursesStore.changeTaskTheory(event)}
        placeholder={'Теория'}
      />

      <input
        placeholder={'Ссылка на документацию'}
        value={task?.original_documentation || ''}
        onChange={event => createCoursesStore.changeTaskDocumentation(event)}
        className={`${styles.title} ${styles.one}`}
      />

      {task?.task_type === Task_types.Theory && <ChoicesBlock />}

      {task?.task_type === Task_types.Practice && <PracticeTexteteaBlock />}
    </div>
  );
});
