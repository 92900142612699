import { observer } from 'mobx-react-lite';
import { useEffect, useRef, useState } from 'react';
import {
  type LessonCuttedI,
  type TaskStringCuttedI,
} from 'Interfaces/coursesInterfaces';
import { TaskString } from 'pages/index';
import lessonArrow from '../../img/lesson-arrow.svg';
import lessonDone from '../../img/arrow.svg';
import lessonNotDone from '../../img/arrow-grey.svg';
import styles from './lesson.module.scss';

const Lesson = observer((data: LessonCuttedI) => {
  const [isLessonOpen, setLessonOpen] = useState(false);
  const [lessonHeight, setLessonHeight] = useState(0);
  const lessonHeightCurrentValue = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const lessonList: HTMLDivElement | null = lessonHeightCurrentValue.current;
    if (lessonList) {
      setLessonHeight(lessonList.scrollHeight);
    }
  }, []);

  return (
    <div className={styles.course__lesson}>
      <div
        className={styles.course__lesson__header}
        onClick={() => setLessonOpen(!isLessonOpen)}
      >
        <h2 className={styles.course__lesson__header__title}>
          {data.name_lesson}
        </h2>
        <div className={styles.course__lesson__states__wrapper}>
          <img
            className={styles.course__lesson__done__status}
            src={data.flag === 'true' ? lessonDone : lessonNotDone}
            alt="lesson-done-status"
          />
          <img
            className={
              isLessonOpen
                ? `${styles.lesson__arrow} ${styles.open}`
                : styles.lesson__arrow
            }
            src={lessonArrow}
            alt="lesson-arrow"
          />
        </div>
      </div>
      <div
        ref={lessonHeightCurrentValue}
        className={styles.course__lesson__taskstrings}
        style={
          isLessonOpen
            ? { maxHeight: `${lessonHeight + 20}px` }
            : { maxHeight: `${0}px` }
        }
      >
        {data.task_string.map(
          (task_string: TaskStringCuttedI, index: number) => {
            return (
              <div className={styles.task__string__block} key={task_string.id}>
                <TaskString
                  task_string={task_string}
                  index={index}
                  length={data.task_string.length}
                />
              </div>
            );
          },
        )}
      </div>
    </div>
  );
});

export default Lesson;
