import { useEffect } from 'react';
import { userStore } from 'Stores/UserStore';
import { observer } from 'mobx-react-lite';
import CompletedCourse from 'components/CompletedCourse/CompletedCourse';
import { type CompletedCourseI } from 'Interfaces/coursesInterfaces';
import { type AchievementI } from 'Interfaces/userInterfaces';
import Achievement from 'components/Achievement/Achievement';
import { InfoBlock } from 'pages/Profiles/MyProfile/components/infoBlock/InfoBlock';
import { Link } from 'react-router-dom';
import { coursesStore } from 'Stores/CourseStore';
import { BASE_URL } from '../../../consturl';
import userLogo from '../../../style/img/UserIcon.svg';
import styles from './Profile.module.scss';

const Profile = observer(() => {
  useEffect(() => {
    document.title = 'Профиль';
    userStore.get_user();
    coursesStore.courseTask.flag = '';
  }, []);

  return (
    <section className={styles.user__profile__wrapper}>
      <div className={styles.user__profile__content__wrapper}>
        <div className={styles.user__profile}>
          <div className={styles.userinfo__form}>
            <div className={styles.user__avatar_wrapper}>
              <h1>Профиль</h1>
              <div className={styles.info__block}>
                <img
                  className={styles.avatar}
                  src={
                    userStore.user_header.avatar_photo
                      ? `${BASE_URL}${userStore.user_header.avatar_photo}`
                      : userLogo
                  }
                  alt="user-photo"
                />
                <div className={styles.info__block_text}>
                  <p className={styles.user__rank}>{userStore.user.rank}</p>
                  <p className={styles.user__exp}>
                    {userStore.user_header.exp} XP
                  </p>
                </div>
              </div>
            </div>

            <InfoBlock info={userStore.user_header.first_name} title={'Имя'} />
            <InfoBlock
              info={userStore.user_header.last_name}
              title={'Фамилия'}
            />
            <InfoBlock info={userStore.user.email} title={'Email'} />

            <label className={styles.label}>
              <input
                type={'checkbox'}
                className={styles.input__checkbox}
                checked={userStore.user_header.is_student}
                disabled
              />{' '}
              Я студент Алабуги
            </label>

            <p>
              Для смены информации перейдите на
              <Link to={'http://is.hr.alabuga.ru'} className={styles.link}>
                {' '}
                HR-платформу
              </Link>
            </p>
          </div>

          <div className={styles.user__courses_block}>
            <h1>Курсы</h1>
            {userStore.started_courses.length ? (
              <ul className={styles.user__started__courses__list}>
                {userStore.started_courses.map((data: CompletedCourseI) => (
                  <CompletedCourse {...data} key={data.id} />
                ))}
              </ul>
            ) : (
              <h2 className={styles.not__courses}>Курсов еще нет</h2>
            )}
          </div>
        </div>
      </div>
      <div className={styles.user__achievements__wrapper}>
        <h1>Достижения</h1>
        <ul className={styles.user__achievements__list}>
          {userStore.achievement.length ? (
            userStore.achievement.map((data: AchievementI) => (
              <Achievement {...data} key={data.name} />
            ))
          ) : (
            <h1 className={styles.user__achievements_none}>
              У вас нет достижений
            </h1>
          )}
        </ul>
      </div>
    </section>
  );
});

export default Profile;
