import { type FC } from 'react';
import styles from '../Faq.module.scss';

export const Page1: FC = () => (
  <div className={styles.page}>
    <h3 className={styles.faq_center}>Введение</h3>
    <br />
    Описание и применение - 2 страница
    <br />
    <br />
    Почему выбирают Python? - 3 страница
    <br />
    <br />
    Отрицательные качества - 4 страница
    <br />
    <br />
    Фреймворк - 5 страница
    <br />
    <br />
    Django Rest Framework(DRF) - 6 страница
    <br />
    <br />
    Sanic - 7 страница
    <br />
    <br />
    Aiohttp - 8 страница
    <br />
    <br />
    Бэкенд разработка - 9 страница
    <br />
    <br />
    MySQL - 10 страница
    <br />
    <br />
    PostgreSQL - 11 страница
    <br />
    <br />
    MongoDB - 12 страница
  </div>
);

export const Page2: FC = () => (
  <div className={styles.page}>
    <h3 className={styles.faq_center}>Python</h3>
    <h4>Описание и применение</h4>
    <br />
    <p>
      Python — универсальный и легко читаемый язык программирования, созданный
      «Гвидо ван Россумом» в конце 1980-х годов и выпущенный в 1991 году. Он
      широко используется в различных областях, включая веб-разработку, науку о
      данных, машинное обучение, автоматизацию и разработку игр.
    </p>
    <br />
    <h4>Применение Python</h4>
    <br />
    <p>
      1. Веб-разработка
      <br />
      2. Python используется для создания серверной части веб-приложений. Он
      часто используется вместе с фреймворками, такими как Django и Flask, для
      разработки мощных и эффективных веб-приложений.
      <br />
      3. Наука о данных
      <br />
      4. Python является одним из самых популярных языков программирования для
      анализа данных и машинного обучения. Библиотеки, такие как NumPy, Pandas и
      TensorFlow, делают его идеальным выбором для обработки и анализа данных.
      <br />
      5. Машинное обучение
      <br />
      6. Python предлагает богатые возможности для разработки алгоритмов
      машинного обучения и создания искусственного интеллекта. Библиотеки, такие
      как Scikit-learn и Keras, позволяют легко создавать и обучать модели
      машинного обучения.
      <br />
      7. Автоматизация
      <br />
      8. Python часто используется для автоматизации рутинных задач и
      скриптинга. Его простота и удобство делают его идеальным выбором для
      написания скриптов.
    </p>
  </div>
);

export const Page3: FC = () => (
  <div className={styles.page}>
    <h3 className={styles.faq_center}>Python</h3>
    <h4>Почему выбирают Python?</h4>
    <br />
    <p>
      1. Интуитивно понятный синтаксис
      <br />
      2. Python известен своей простотой и читаемостью кода, что делает его
      идеальным для начинающих программистов.
      <br />
      3. Этот язык ставит акцент на ясности и лаконичности, что облегчает
      понимание его конструкций даже без предварительного опыта.
      <br />
      4. Широкое сообщество и экосистема
      <br />
      5. Python обладает огромным сообществом разработчиков, что привело к
      формированию обширной экосистемы библиотек и фреймворков. Это позволяет
      разработчикам использовать готовые решения для различных задач и быстро
      решать проблемы.
      <br />
      6. Поддержка различных парадигм программирования
      <br />
      7. Python поддерживает разнообразные стили программирования, включая
      объектно-ориентированное, процедурное и функциональное программирование.
      Это позволяет разработчикам выбирать подход, который наилучшим образом
      соответствует их потребностям и предпочтениям.
      <br />
      8. Кроссплатформенность
      <br />
      9. Python работает на различных операционных системах, включая Windows,
      MacOS и Linux. Это делает его универсальным инструментом разработки,
      который можно использовать на различных платформах без изменения кода.
      <br />
      10. Хорошая интеграция с другими языками
      <br />
      11. Python легко интегрируется с другими языками программирования, такими
      как C/C++ и Java. Это позволяет разработчикам использовать библиотеки и
      компоненты, написанные на других языках, в своих Python-проектах без
      необходимости переписывать их с нуля.
    </p>
  </div>
);

export const Page4: FC = () => (
  <div className={styles.page}>
    <h3 className={styles.faq_center}>Python</h3>
    <h4>Отрицательные качества</h4>
    <br />
    1. Низкая скорость выполнения
    <br />
    2. Python является интерпретируемым языком программирования, что делает его
    менее эффективным по сравнению с компилируемыми языками, такими как C или
    C++. Это может привести к более долгому времени выполнения программ,
    особенно для вычислительно интенсивных задач
    <br />
    3. Ограничения GIL
    <br />
    4. GIL (Global Interpreter Lock) ограничивает выполнение многопоточных
    программ в Python, что может привести к проблемам с параллельным выполнением
    кода и использованием многопоточности для повышения производительности.
    <br />
    5. Неидеально для мобильных приложений
    <br />
    6. Python имеет ограниченную поддержку для мобильной разработки по сравнению
    с другими языками, такими как Java или Kotlin для Android и Swift для iOS.
    Это связано с недостаточной оптимизацией и высоким потреблением ресурсов на
    мобильных устройствах.
    <br />
    7. Потребление памяти
    <br />
    8. Python может потреблять больше памяти для выполнения программ, особенно
    по сравнению с языками, использующими статическую типизацию. Это может быть
    проблемой для приложений с ограниченными ресурсами, таких как встраиваемые
    системы или мобильные устройства.
  </div>
);
export const Page5: FC = () => (
  <div className={styles.page}>
    <h3 className={styles.faq_center}>Python</h3>
    <h4>Фреймворк</h4>
    <br />
    <p>
      Набор готовых инструментов, правил и библиотек, который помогает
      разработчикам создавать программное обеспечение более эффективно и быстро.
      Представьте фреймворк как набор инструкций или шаблонов, которые помогают
      вам строить дом: он предоставляет вам необходимые материалы и указания,
      чтобы вы могли построить дом, но не ограничивает вас в том, как он должен
      выглядеть. Фреймворк определяет структуру и правила разработки приложения,
      что делает процесс разработки более структурированным и эффективным.
    </p>
  </div>
);

export const Page6: FC = () => (
  <div className={styles.page}>
    <h3 className={styles.faq_center}>Python</h3>
    <h4>Django Rest Framework (DRF)</h4>
    <br />
    <p>
      Мощный фреймворк для создания веб-API на языке Python с использованием
      фреймворка Django. Он предоставляет набор инструментов и функций,
      упрощающих процесс разработки API. Некоторые из ключевых особенностей DRF
      включают в себя:
    </p>
    <br />
    <p>
      1. Сериализация данных
      <br />
      2. Предоставляет удобные средства сериализации и десериализации данных
      между форматами, такими как JSON, XML и другими, что упрощает передачу
      данных между клиентом и сервером.
      <br />
      3. Предоставляет готовые средства для реализации аутентификации и
      авторизации пользователей, включая различные методы аутентификации
      (токены, JWT, OAuth) и правила доступа, что обеспечивает безопасность
      вашего API.
      <br />
      4. Автоматизирует обработку запросов CRUD (Create, Read, Update, Delete)
      для ваших моделей Django, что упрощает создание API для работы с данными
      вашего приложения.
    </p>
    <br />
    <p>
      DRF позволяет разработчикам быстро и эффективно создавать мощные и гибкие
      веб-API, что делает его популярным выбором для разработки современных
      веб-приложений на Python.
    </p>
  </div>
);

export const Page7: FC = () => (
  <div className={styles.page}>
    <h3 className={styles.faq_center}>Python</h3>
    <h4>Sanic</h4>
    <br />
    <p>
      Асинхронный веб-фреймворк на языке Python, который позволяет создавать
      высокопроизводительные веб-приложения с асинхронной обработкой запросов.
      Он основан на принципах асинхронного программирования и предоставляет
      множество возможностей для создания масштабируемых и быстрых приложений.
      Некоторые из ключевых особенностей Sanic:
    </p>
    <br />
    <p>
      1. Использует асинхронные возможности Python 3.5+ для обработки запросов
      без блокировки потоков, что позволяет обеспечить высокую
      производительность и отзывчивость приложения.
      <br />
      2. Обладает интуитивно понятным API, аналогичным Flask, что делает его
      легким для изучения и использования даже для новичков в асинхронном
      программировании.
      <br />
      3. Обеспечивает хорошую масштабируемость благодаря использованию
      асинхронных возможностей Python и оптимизированным механизмам обработки
      запросов.
    </p>
    <br />
    <p>
      Sanic отлично подходит для создания высокопроизводительных веб-приложений,
      включая API, стриминговые сервисы и приложения реального времени.
    </p>
  </div>
);

export const Page8: FC = () => (
  <div className={styles.page}>
    <h3 className={styles.faq_center}>Python</h3>
    <h4>Aiohttp</h4>
    <br />
    <p>
      Асинхронная библиотека для работы с HTTP-запросами в языке
      программирования Python. Она предоставляет возможности как для создания
      веб-серверов, так и для отправки и обработки HTTP-запросов на стороне
      клиента. Вот некоторые ключевые особенности aiohttp:
    </p>
    <br />
    <p>
      1. Одной из основных особенностей aiohttp является ее способность
      обрабатывать HTTP-запросы асинхронно, используя возможности асинхронного
      программирования в Python. Это позволяет обрабатывать множество запросов
      параллельно без блокировки потоков, что повышает производительность и
      масштабируемость приложений.
      <br />
      2. aiohttp предоставляет разработчикам гибкие средства для создания как
      веб-серверов, так и клиентских приложений. Он позволяет легко настраивать
      и расширять функциональность веб-сервера или клиента в зависимости от
      конкретных потребностей проекта.
      <br />
      3. Несмотря на свою мощь, aiohttp остается относительно простым в
      использовании. Он предоставляет интуитивно понятный API, который позволяет
      быстро создавать и настраивать веб-серверы и клиентские приложения с
      минимальными усилиями.
    </p>
    <br />
    <p>
      Aiohttp является популярным выбором для создания высокопроизводительных и
      масштабируемых веб-приложений, особенно в области асинхронного
      программирования. Его гибкость, производительность и простота
      использования делают его привлекательным инструментом для разработчиков,
      работающих с сетевыми приложениями на Python.
    </p>
  </div>
);
export const Page9: FC = () => (
  <div className={styles.page}>
    <h3 className={styles.faq_center}>Python</h3>
    <h4>Бэкенд разработка</h4>
    <br />
    Бэкенд разработка относится к созданию и поддержке серверной части
    веб-приложений и программного обеспечения. Она включает в себя работу с
    базами данных, обработку запросов от клиентов, управление безопасностью и
    другие аспекты, связанные с обработкой данных и бизнес-логикой
    приложения.Вот некоторая информация о бэкенд разработке:
    <br />
    1. Бэкенд разработка часто выполняется на языках программирования, таких как
    Java, Python, Ruby и других.
    <br />
    2. Одним из ключевых инструментов для бэкенд разработки являются базы
    данных, такие как MySQL, PostgreSQL и MongoDB.
    <br />
    3. Бэкенд разработчики также работают с различными фреймворками и
    инструментами, которые помогают им создавать и поддерживать веб-приложения.
    <br />
    4. Бэкенд разработчики обычно сотрудничают с фронтенд разработчиками, чтобы
    создать полноценное веб-приложение.
    <br />
    5. Навыки бэкенд разработчика могут варьироваться от уровня junior до senior
    и архитектора.
    <br />
    Аiohttp является популярным выбором для создания высокопроизводительных и
    масштабируемых веб-приложений, особенно в области асинхронного
    программирования. Его гибкость, производительность и простота использования
    делают его привлекательным инструментом для разработчиков, работающих с
    сетевыми приложениями на Python.
  </div>
);

export const Page10: FC = () => (
  <div className={styles.page}>
    <h3 className={styles.faq_center}>Python</h3>
    <h4>MySQL</h4>
    <br />
    MySQL - это одна из самых популярных реляционных систем управления базами
    данных (СУБД). Она является открытой и бесплатной для использования в
    большинстве случаев, и широко применяется в веб-разработке и других
    областях. Особенности MySQL:
    <br />
    1. Реляционная модель: MySQL хранит данные в табличной форме, где данные
    организованы в виде таблиц, состоящих из строк и столбцов.
    <br />
    2. SQL: MySQL основана на языке SQL (Structured Query Language), который
    используется для работы с данными. SQL позволяет выполнять различные
    операции, такие как создание, чтение, обновление и удаление данных в базе
    данных.
    <br />
    3. Хорошая производительность: MySQL обеспечивает высокую производительность
    при обработке запросов и доступе к данным. Она может обрабатывать большие
    объемы данных и поддерживать одновременные подключения от нескольких
    клиентов.
    <br />
    4. Масштабируемость: MySQL может быть масштабирована горизонтально
    (добавление дополнительных серверов) или вертикально (увеличение мощности
    сервера) для обработки растущей нагрузки.
    <br />
    5. Транзакции и целостность данных: MySQL поддерживает транзакции, которые
    обеспечивают целостность данных и позволяют выполнять группу операций как
    единое целое.
    <br />
    MySQL имеет обширное сообщество разработчиков, что обеспечивает доступ к
    множеству ресурсов, документации и помощи.
  </div>
);

export const Page11: FC = () => (
  <div className={styles.page}>
    <h3 className={styles.faq_center}>Python</h3>
    <h4>PostgreSQL</h4>
    <br />
    PostgreSQL - это мощная и расширяемая реляционная система управления базами
    данных (СУБД). Она также является открытой и бесплатной для использования.
    Особенности PostgreSQL:
    <br />
    1. Расширенные возможности: PostgreSQL предлагает более широкий набор
    функций и возможностей по сравнению с другими реляционными СУБД. Она
    поддерживает более сложные типы данных, такие как массивы, JSON, XML и
    географические данные. PostgreSQL также позволяет создавать пользовательские
    функции и хранимые процедуры.
    <br />
    2. SQL: Как и MySQL, PostgreSQL основана на языке SQL и предоставляет мощные
    возможности для работы с данными.
    <br />
    3. Целостность данных: PostgreSQL обеспечивает строгую целостность данных и
    поддерживает различные уровни изоляции транзакций. Она также предлагает
    механизмы для резервного копирования и восстановления данных для обеспечения
    их безопасности.
    <br />
    4. Масштабируемость: PostgreSQL может быть масштабирована горизонтально и
    вертикально, чтобы обрабатывать большие объемы данных и увеличивать
    производительность.
    <br />
    5. Поддержка распределенных систем: PostgreSQL предоставляет возможность
    создания и управления распределенными базами данных, что позволяет
    обрабатывать данные на нескольких серверах.
    <br />
    PostgreSQL активно разрабатывается и обновляется сообществом разработчиков,
    что делает его одной из самых мощных и гибких реляционных СУБД.
  </div>
);
export const Page12: FC = () => (
  <div className={styles.page}>
    <h3 className={styles.faq_center}>Python</h3>
    <h4>MongoDB</h4>
    <br />
    MongoDB - это система управления базами данных (СУБД) из категории NoSQL,
    которая использует документоориентированную модель данных. Она предоставляет
    гибкую и масштабируемую платформу для хранения и обработки
    неструктурированных данных. Особенности MongoDB:
    <br />
    1. Документоориентированная модель: MongoDB хранит данные в виде
    JSON-подобных документов, которые могут содержать различные поля и их
    значения. Это позволяет гибко изменять структуру данных без необходимости
    обновления схемы.
    <br />
    2. Гибкая схема данных: MongoDB не требует строгой схемы данных, поэтому
    каждый документ в коллекции может иметь различную структуру. Это особенно
    полезно в ситуациях, когда данные могут меняться или иметь разные поля для
    каждого объекта.
    <br />
    3. Масштабируемость: MongoDB может быть масштабирована горизонтально путем
    добавления дополнительных серверов для обработки больших объемов данных и
    высоких нагрузок.
    <br />
    4. Высокая производительность: MongoDB обеспечивает быстрый доступ к данным
    и хорошую производительность при выполнении запросов благодаря встроенной
    поддержке индексов и возможности горизонтального масштабирования.
    <br />
    5. Поддержка поиска и агрегации: MongoDB предоставляет мощные возможности
    для поиска и агрегации данных. Она поддерживает гибкие запросы и операции
    агрегации для обработки и анализа данных.
    <br />
    MongoDB хорошо подходит для работы с большими объемами неструктурированных
    данных, а также для разработки приложений, требующих гибкости и
    масштабируемости.
  </div>
);
