import {
  choice_item,
  initialState,
  lesson_item,
  string_item,
  task_item,
} from 'pages/CreateCourse/components/CreateCourseTable/components/mokData';
import { type ChangeEvent } from 'react';
import { makeAutoObservable } from 'mobx';
import {
  type CreateChoicesI,
  type CreateCourseI,
} from 'Interfaces/coursesInterfaces';
import { Type_choices } from 'Interfaces/userInterfaces';
import axios from 'axios';
import { BASE_URL } from 'consturl';
import { PublicNewCourseI } from 'Interfaces/adminInterfaces';
import { userStore } from './UserStore';

class CreateCourseStore {
  // переменные
  selectLesson = 0;
  selectTask = 0;
  selectString = 0;
  createdCourse: CreateCourseI = initialState;
  isOpenCreateCourseTable = false;
  choices: CreateChoicesI[] = [];
  isChecked = false;
  token = userStore.token;
  picName = '';

  constructor() {
    this.handleFileUpload = this.handleFileUpload.bind(this);
    makeAutoObservable(this);
  }

  setSelectLesson(value: number) {
    this.selectLesson = value;
  }

  setSelectTask(value: number) {
    this.selectTask = value;
  }

  setChoices(value: CreateChoicesI[]) {
    this.choices = value;
  }

  setSelectString(value: number) {
    this.selectString = value;
  }

  setCreatedCourse(value: CreateCourseI) {
    this.createdCourse = value;
  }

  setIsOpenCreateCourseTable(value: boolean) {
    this.isOpenCreateCourseTable = value;
  }

  addCourseName(event: ChangeEvent<HTMLInputElement>) {
    this.createdCourse = {
      ...this.createdCourse,
      name_course: event.target.value,
    };
  }

  addCourseDescription(event: ChangeEvent<HTMLTextAreaElement>) {
    this.createdCourse = {
      ...this.createdCourse,
      detailed_description: event.target.value,
    };
  }

  changeLessonName(event: ChangeEvent<HTMLInputElement>) {
    this.createdCourse = {
      ...this.createdCourse,
      lesson: this.createdCourse.lesson.map((lesson, index) =>
        index === this.selectLesson
          ? { ...lesson, name_lesson: event.target.value }
          : lesson,
      ),
    };
  }

  changeTextForChoice(id: number, event: ChangeEvent<HTMLInputElement>) {
    this.createdCourse = {
      ...this.createdCourse,
      lesson: this.createdCourse.lesson.map((lesson, index) =>
        index === this.selectLesson
          ? {
            ...lesson,
            task_string: lesson.task_string.map((string, index) =>
              index === this.selectString
                ? {
                  ...string,
                  task: string.task.map((task, index) =>
                    index === this.selectTask
                      ? {
                        ...task,
                        choices: task.choices?.map((choice, index) =>
                          index === id
                            ? { ...choice, title: event.target.value }
                            : choice,
                              ),
                            }
                      : task,
                      ),
                    }
                : string,
              ),
          }
          : lesson,
      ),
    };
  }

  changeStatusForChoice(id: number, event: ChangeEvent<HTMLInputElement>) {
    this.createdCourse = {
      ...this.createdCourse,
      lesson: this.createdCourse.lesson.map((lesson, index) =>
        index === this.selectLesson
          ? {
            ...lesson,
            task_string: lesson.task_string.map((string, index) =>
              index === this.selectString
                ? {
                  ...string,
                  task: string.task.map((task, index) =>
                    index === this.selectTask
                      ? {
                        ...task,
                        choices: task.choices?.map((choice, index) =>
                          index === id
                            ? {
                              ...choice,
                              is_correct: event.target.checked,
                                    }
                            : choice,
                              ),
                      }
                      : task,
                      ),
                }
                : string,
              ),
            }
          : lesson,
      ),
    };
  }

  addLesson() {
    this.createdCourse = {
      ...this.createdCourse,
      lesson: [...this.createdCourse.lesson, lesson_item],
    };
  }

  deleteLesson() {
    this.createdCourse = {
      ...this.createdCourse,
      lesson: this.createdCourse.lesson.filter(
        (_, index) => index !== this.selectLesson,
      ),
    };
    if (this.selectLesson >= this.createdCourse.lesson.length) {
      this.setSelectLesson(this.createdCourse.lesson.length - 1);
    }
  }

  addString() {
    this.createdCourse = {
      ...this.createdCourse,
      lesson: this.createdCourse.lesson.map((lesson, index) =>
        index === this.selectLesson
          ? { ...lesson, task_string: [...lesson.task_string, string_item] }
          : lesson,
      ),
    };
  }

  deleteString() {
    const currentLesson = this.createdCourse.lesson[this.selectLesson];
    if (!currentLesson) return;

    this.createdCourse = {
      ...this.createdCourse,
      lesson: this.createdCourse.lesson.map((lesson, index) =>
        index === this.selectLesson && this.createdCourse.lesson.length !== 0
          ? {
            ...lesson,
            task_string: lesson.task_string.filter(
              (_, index) => index !== this.selectString,
              ),
          }
          : lesson,
      ),
    };
    if (this.selectString >= currentLesson.task_string.length - 1) {
      this.setSelectString(currentLesson.task_string.length - 2);
    }
  }

  addTask() {
    this.createdCourse = {
      ...this.createdCourse,
      lesson: this.createdCourse.lesson.map((lesson, lessonIndex) =>
        lessonIndex === this.selectLesson
          ? {
            ...lesson,
            task_string: lesson.task_string.map((taskString, stringIndex) =>
              stringIndex === this.selectString
                ? {
                  ...taskString,
                  task: [...taskString.task, task_item],
                    }
                : taskString,
              ),
            }
          : lesson,
      ),
    };
  }

  handleChangeType = (value: string) => {
    if (!this.createdCourse) {
      console.error('createdCourse is not initialized');
      return;
    }

    this.createdCourse = {
      ...this.createdCourse,
      lesson: this.createdCourse.lesson.map((lesson, lessonIndex) =>
        lessonIndex === this.selectLesson
          ? {
            ...lesson,
            task_string: lesson.task_string.map((taskString, stringIndex) =>
              stringIndex === this.selectString
                ? {
                  ...taskString,
                  task: taskString.task.map((taskItem, taskIndex) =>
                    taskIndex === this.selectTask
                      ? {
                        ...taskItem,
                        task_type: value,
                            }
                      : taskItem,
                      ),
                    }
                : taskString,
              ),
          }
          : lesson,
      ),
    };
  };

  changeTaskName = (event: ChangeEvent<HTMLInputElement>) => {
    if (!this.createdCourse) {
      console.error('createdCourse is not initialized');
      return;
    }

    this.createdCourse = {
      ...this.createdCourse,
      lesson: this.createdCourse.lesson.map((lesson, lessonIndex) =>
        lessonIndex === this.selectLesson
          ? {
            ...lesson,
            task_string: lesson.task_string.map((taskString, stringIndex) =>
              stringIndex === this.selectString
                ? {
                  ...taskString,
                  task: taskString.task.map((taskItem, taskIndex) =>
                    taskIndex === this.selectTask
                      ? {
                        ...taskItem,
                        name_task: event.target.value,
                            }
                      : taskItem,
                      ),
                }
                : taskString,
              ),
            }
          : lesson,
      ),
    };
  };

  changeTaskDocumentation = (event: ChangeEvent<HTMLInputElement>) => {
    if (!this.createdCourse) {
      console.error('createdCourse is not initialized');
      return;
    }

    this.createdCourse = {
      ...this.createdCourse,
      lesson: this.createdCourse.lesson.map((lesson, lessonIndex) =>
        lessonIndex === this.selectLesson
          ? {
            ...lesson,
            task_string: lesson.task_string.map((taskString, stringIndex) =>
              stringIndex === this.selectString
                ? {
                  ...taskString,
                  task: taskString.task.map((taskItem, taskIndex) =>
                    taskIndex === this.selectTask
                      ? {
                        ...taskItem,
                        original_documentation: event.target.value,
                            }
                      : taskItem,
                      ),
                    }
                : taskString,
              ),
            }
          : lesson,
      ),
    };
  };

  changeTaskXp = (event: ChangeEvent<HTMLInputElement>) => {
    if (!this.createdCourse) {
      console.error('createdCourse is not initialized');
      return;
    }

    const xpValue = Number(event.target.value);

    if (isNaN(xpValue)) {
      console.error('Invalid XP value');
      return;
    }

    this.createdCourse = {
      ...this.createdCourse,
      lesson: this.createdCourse.lesson.map((lesson, lessonIndex) =>
        lessonIndex === this.selectLesson
          ? {
            ...lesson,
            task_string: lesson.task_string.map((taskString, stringIndex) =>
              stringIndex === this.selectString
                ? {
                  ...taskString,
                  task: taskString.task.map((taskItem, taskIndex) =>
                    taskIndex === this.selectTask
                      ? {
                        ...taskItem,
                        xp: xpValue,
                            }
                      : taskItem,
                      ),
                    }
                : taskString,
              ),
            }
          : lesson,
      ),
    };
  };

  handleChangeComplexity(newComplexity: string) {
    this.createdCourse = {
      ...this.createdCourse,
      lesson: this.createdCourse.lesson.map((lesson, lessonIndex) =>
        lessonIndex === this.selectLesson
          ? {
            ...lesson,
            task_string: lesson.task_string.map((taskString, stringIndex) =>
              stringIndex === this.selectString
                ? {
                  ...taskString,
                  task: taskString.task.map((taskItem, taskIndex) =>
                    taskIndex === this.selectTask
                      ? {
                        ...taskItem,
                        complexity: newComplexity,
                            }
                      : taskItem,
                      ),
                    }
                : taskString,
              ),
            }
          : lesson,
      ),
    };
  }

  handleChangeLanguage = (value: string) => {
    if (!this.createdCourse) {
      console.error('createdCourse is not initialized');
      return;
    }

    this.createdCourse = {
      ...this.createdCourse,
      lesson: this.createdCourse.lesson.map((lesson, lessonIndex) =>
        lessonIndex === this.selectLesson
          ? {
            ...lesson,
            task_string: lesson.task_string.map((taskString, stringIndex) =>
              stringIndex === this.selectString
                ? {
                  ...taskString,
                  task: taskString.task.map((taskItem, taskIndex) =>
                    taskIndex === this.selectTask
                      ? {
                        ...taskItem,
                        language_type: value,
                            }
                      : taskItem,
                      ),
                }
                : taskString,
              ),
            }
          : lesson,
      ),
    };
  };

  changeTaskTheory = (event: ChangeEvent<HTMLTextAreaElement>) => {
    if (!this.createdCourse) {
      console.error('createdCourse is not initialized');
      return;
    }

    this.createdCourse = {
      ...this.createdCourse,
      lesson: this.createdCourse.lesson.map((lesson, lessonIndex) =>
        lessonIndex === this.selectLesson
          ? {
            ...lesson,
            task_string: lesson.task_string.map((taskString, stringIndex) =>
              stringIndex === this.selectString
                ? {
                  ...taskString,
                  task: taskString.task.map((taskItem, taskIndex) =>
                    taskIndex === this.selectTask
                      ? {
                        ...taskItem,
                        theory: event.target.value,
                            }
                      : taskItem,
                      ),
                }
                : taskString,
              ),
          }
          : lesson,
      ),
    };
  };

  changeTaskAutoTest = (event: ChangeEvent<HTMLTextAreaElement>) => {
    if (!this.createdCourse) {
      console.error('createdCourse is not initialized');
      return;
    }

    this.createdCourse = {
      ...this.createdCourse,
      lesson: this.createdCourse.lesson.map((lesson, lessonIndex) =>
        lessonIndex === this.selectLesson
          ? {
            ...lesson,
            task_string: lesson.task_string.map((taskString, stringIndex) =>
              stringIndex === this.selectString
                ? {
                  ...taskString,
                  task: taskString.task.map((taskItem, taskIndex) =>
                    taskIndex === this.selectTask
                      ? {
                        ...taskItem,
                        auto_test: event.target.value,
                            }
                      : taskItem,
                      ),
                }
                : taskString,
              ),
          }
          : lesson,
      ),
    };
  };

  changeTaskChioceType = (value: boolean) => {
    if (!this.createdCourse) {
      console.error('createdCourse is not initialized');
      return;
    }

    this.createdCourse = {
      ...this.createdCourse,
      lesson: this.createdCourse.lesson.map((lesson, lessonIndex) =>
        lessonIndex === this.selectLesson
          ? {
            ...lesson,
            task_string: lesson.task_string.map((taskString, stringIndex) =>
              stringIndex === this.selectString
                ? {
                  ...taskString,
                  task: taskString.task.map((taskItem, taskIndex) =>
                    taskIndex === this.selectTask
                      ? {
                        ...taskItem,
                        type_choices: value
                          ? Type_choices.Checkbox
                          : Type_choices.Radio,
                            }
                      : taskItem,
                      ),
                }
                : taskString,
              ),
            }
          : lesson,
      ),
    };
  };

  changeTaskNameFunc = (event: ChangeEvent<HTMLTextAreaElement>) => {
    if (!this.createdCourse) {
      console.error('createdCourse is not initialized');
      return;
    }

    this.createdCourse = {
      ...this.createdCourse,
      lesson: this.createdCourse.lesson.map((lesson, lessonIndex) =>
        lessonIndex === this.selectLesson
          ? {
            ...lesson,
            task_string: lesson.task_string.map((taskString, stringIndex) =>
              stringIndex === this.selectString
                ? {
                  ...taskString,
                  task: taskString.task.map((taskItem, taskIndex) =>
                    taskIndex === this.selectTask
                      ? {
                        ...taskItem,
                        name_func: event.target.value,
                            }
                      : taskItem,
                      ),
                    }
                : taskString,
              ),
            }
          : lesson,
      ),
    };
  };

  addChoice() {
    const currentChoices = this.getCurrentChoices();
    this.setChoices([...currentChoices, choice_item]);

    this.createdCourse = {
      ...this.createdCourse,
      lesson: this.createdCourse.lesson.map((lesson, lessonIndex) =>
        lessonIndex === this.selectLesson
          ? {
            ...lesson,
            task_string: lesson.task_string.map((taskString, stringIndex) =>
              stringIndex === this.selectString
                ? {
                  ...taskString,
                  task: taskString.task.map((taskItem, taskIndex) =>
                    taskIndex === this.selectTask
                      ? {
                        ...taskItem,
                        choices: [...currentChoices, choice_item],
                            }
                      : taskItem,
                      ),
                }
                : taskString,
              ),
          }
          : lesson,
      ),
    };
  }

  deleteChoice() {
    const currentChoices = this.getCurrentChoices();

    if (currentChoices.length === 0) {
      return;
    }

    const updatedChoices = currentChoices.slice(0, -1);

    this.setChoices(updatedChoices);

    this.createdCourse = {
      ...this.createdCourse,
      lesson: this.createdCourse.lesson.map((lesson, lessonIndex) =>
        lessonIndex === this.selectLesson
          ? {
            ...lesson,
            task_string: lesson.task_string.map((taskString, stringIndex) =>
              stringIndex === this.selectString
                ? {
                  ...taskString,
                  task: taskString.task.map((taskItem, taskIndex) =>
                    taskIndex === this.selectTask
                      ? {
                        ...taskItem,
                        choices: updatedChoices,
                            }
                      : taskItem,
                      ),
                }
                : taskString,
              ),
            }
          : lesson,
      ),
    };
  }

  getCurrentChoices() {
    const currentLesson = this.createdCourse.lesson[this.selectLesson];
    if (!currentLesson) return [];

    const currentTaskString = currentLesson.task_string[this.selectString];
    if (!currentTaskString) return [];

    const currentTask = currentTaskString.task[this.selectTask];
    if (!currentTask) return [];

    return currentTask.choices || [];
  }

  getCurrentComplexity() {
    const currentLesson = this.createdCourse.lesson[this.selectLesson];
    if (!currentLesson) return null;

    const currentTaskString = currentLesson.task_string[this.selectString];
    if (!currentTaskString) return null;

    const currentTask = currentTaskString.task[this.selectTask];
    if (!currentTask) return null;

    return currentTask.complexity || null;
  }

  getCurrentLanguage() {
    const currentLesson = this.createdCourse.lesson[this.selectLesson];
    if (!currentLesson) return null;

    const currentTaskString = currentLesson.task_string[this.selectString];
    if (!currentTaskString) return null;

    const currentTask = currentTaskString.task[this.selectTask];
    if (!currentTask) return null;

    return currentTask.language_type || null;
  }

  getCurrentTaskType() {
    const currentLesson = this.createdCourse.lesson[this.selectLesson];
    if (!currentLesson) return null;

    const currentTaskString = currentLesson.task_string[this.selectString];
    if (!currentTaskString) return null;

    const currentTask = currentTaskString.task[this.selectTask];
    if (!currentTask) return null;

    return currentTask.task_type || null;
  }

  createCourse() {
    axios
      .post(BASE_URL + '/api/v1/admin/course/form', this.createdCourse, {
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
      })
      .then(response => {
        console.log(response.data, 'ok');
        this.createdCourse = initialState;
      })
      .catch(error => {
        console.error(`Ошибка: ${error}`);
      });
    console.log(JSON.parse(JSON.stringify(this.createdCourse)));
  }

  handleFileUpload(event: ChangeEvent<HTMLInputElement>) {
    const data = event.target.files && event.target.files[0];

    if (data) {
      this.picName = data.name;
    }
  }
}

export const createCoursesStore = new CreateCourseStore();
