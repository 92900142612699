import { observer } from 'mobx-react-lite';
import { websocketStore } from 'Stores/WebsocketStore';
import { useState } from 'react';
import Send from 'style/img/Send.png';
import { chatStore } from 'Stores/ChatStore';
import { WEBSOCKET_MSG } from '../../../../Const';
import styles from './SendMessage.module.scss';
const SendMessage = observer(() => {
  const [message, setMessage] = useState('');

  return (
    <div className={styles.message__block}>
      {chatStore.updateMessage && (
        <div className={styles.update__message__inf}>
          <p>{chatStore.chat?.chat[chatStore.indexUpdateMessage].text}</p>
        </div>
      )}
      <div className={styles.send__message__block}>
        <input
          type="text"
          onChange={event => setMessage(event.target.value)}
          value={message}
          placeholder="Введите сообщение"
          className={styles.input__message}
          onKeyDown={event => {
            if (event.key === 'Enter' && message) {
              setMessage('');
              if (chatStore.updateMessage) {
                websocketStore.sendMessage(
                  WEBSOCKET_MSG.UPDATE_MESSAGE,
                  message,
                  chatStore.messageId,
                );
                chatStore.setUpdateMessage(false);
              } else {
                websocketStore.sendMessage(
                  WEBSOCKET_MSG.SEND_MESSAGE,
                  message,
                  chatStore.chat.id,
                );
              }
            }
          }}
        />
        <button
          onClick={event => {
            if (message) {
              if (chatStore.updateMessage) {
                websocketStore.sendMessage(
                  WEBSOCKET_MSG.UPDATE_MESSAGE,
                  message,
                  chatStore.messageId,
                );
                chatStore.setUpdateMessage(false);
              } else {
                websocketStore.sendMessage(
                  WEBSOCKET_MSG.SEND_MESSAGE,
                  message,
                  chatStore.chat.id,
                );
              }
            }
            setMessage('');
          }}
        >
          <img src={Send} alt="" />
        </button>
      </div>
    </div>
  );
});

export default SendMessage;
