import { type ModalPlatformCartI } from 'Interfaces/helpInterfaces';
import oficialIcon from '../../img/oficialIcon.svg';
import HrPlatform from '../../img/HrPlatform.svg';
import Site from '../../img/Site.svg';

export const PLATFORM_CART_NAVIGATE: ModalPlatformCartI[] = [
  {
    text: 'Официальный сайт',
    img: oficialIcon,
    link: 'http://alabuga.ru',
  },
  {
    text: 'HR-платформа',
    img: HrPlatform,
    link: 'http://hr.alabuga.ru',
  },
];
