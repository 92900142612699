import { type ButtonI } from 'Interfaces/helpInterfaces';
import { observer } from 'mobx-react-lite';
import styles from './Button.module.scss';

const Button = observer((data: ButtonI) => {
  return (
    <button
      onClick={data.onClick}
      type={data.type}
      className={[data.className, styles.button].join(' ')}
    >
      {data.text}
    </button>
  );
});
export default Button;
