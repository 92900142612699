import { makeAutoObservable } from 'mobx';
import axios from 'axios';
import { BASE_URL } from 'consturl';
import {
  type ConsoleOutputI,
  type CourseI,
  type CourseCuttedI,
  type TaskI,
  type StatisticsCourseI,
} from 'Interfaces/coursesInterfaces';
import {
  type InfoDataCourseI,
  type TableCourseI,
  type TableUsersI,
} from 'Interfaces/userInterfaces';
import { userStore } from './UserStore';

class CourseStore {
  // переменные
  coursesList: CourseCuttedI[] = [];
  infoDataCourse: InfoDataCourseI = {
    id: 0,
    avatar_photo: '',
    first_name: '',
    last_name: '',
  };
  course: CourseI = {
    detailed_description: '',
    lesson: [],
    name_course: '',
    preview_image: '',
    user_data: this.infoDataCourse,
  };
  usersInTable: TableUsersI[] = [];
  usersInTableCourse: TableCourseI[] = [];
  courseTask: TaskI = {};
  last_completed_task = 0;
  userCode = '';
  isOpenAnswer = -1;
  isOpenCourse = -1;
  isOpenTable = false;
  isOpenTableCourse = false;
  consoleOutput: ConsoleOutputI = {
    message: {
      result: null,
      output: [''],
      success: false,
    },
  };
  access = true;
  completedTask?: null | boolean;
  loading = false;
  error: boolean | string = false;
  token = userStore.token;
  numberSliderPython = 0;

  statisticsCourse: StatisticsCourseI = { users: [] };
  answerCode: ConsoleOutputI = {};
  btnCourseHoverEvent = true;

  constructor() {
    makeAutoObservable(this);
  }

  setBtnCourseHoverEvent(value: boolean) {
    this.btnCourseHoverEvent = value;
  }

  setLoading(value: boolean) {
    this.loading = value;
  }

  setConsoleOutput(value: ConsoleOutputI) {
    this.consoleOutput = value;
  }

  setCourse(value: CourseI) {
    this.course = value;
  }

  setCoursesList(value: CourseCuttedI[]) {
    this.coursesList = value;
  }

  setUsers(value: TableUsersI[]) {
    this.usersInTable = value;
  }

  setUsersInTableCourse(value: TableCourseI[]) {
    this.usersInTableCourse = value;
  }

  setAccess(value: boolean) {
    this.access = value;
  }

  setCourseTask(value: TaskI) {
    this.courseTask = value;
  }

  setIsOpenAnswer(value: number) {
    this.isOpenAnswer = value;
  }

  setIsOpenTable(value: boolean) {
    this.isOpenTable = value;
  }

  setIsOpenTableCourse(value: boolean) {
    this.isOpenTableCourse = value;
  }

  setLastCompletedTask(value: number) {
    this.last_completed_task = value;
  }

  setError(value: boolean) {
    this.error = value;
  }

  setUserCode(code: string) {
    this.userCode = code;
  }

  setNumberSliderPython(value: number) {
    this.numberSliderPython = value;
  }

  setCompletedTask(value: boolean | null) {
    this.completedTask = value;
  }

  setStatisticsCourse(value: StatisticsCourseI) {
    this.statisticsCourse = value;
  }

  setPrintAnswersCode(value: ConsoleOutputI) {
    this.answerCode = value;
  }

  getCourses() {
    this.setLoading(true);
    axios
      .get(`${BASE_URL}/api/v1/course/homepage`, {
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
      })
      .then(response => {
        this.setLoading(false);
        this.setCoursesList(response.data);
      });
  }

  getCourse(id?: string) {
    this.setLoading(true);
    axios
      .get(`${BASE_URL}/api/v1/course/${id}`, {
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
      })
      .then(response => {
        this.setCourse(response.data);
        this.setLoading(false);
      })
      .catch(error => {
        this.setError(true);
        console.error('Ошибка при получении данных:', error);
      });
  }

  getUserCourses(user_id: number, course_id: number) {
    this.setLoading(true);
    axios
      .get(`${BASE_URL}/api/v1/admin/course/users/${user_id}/${course_id}`, {
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
      })
      .then(response => {
        this.setCourse(response.data);
        this.setLoading(false);
      })
      .catch(error => {
        this.setError(true);
        console.error('Ошибка при получении данных:', error);
      });
  }

  getUsersForTable(task_id: number) {
    axios
      .get(`${BASE_URL}/api/v1/admin/course/solutions/${task_id}`, {
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
      })
      .then(response => {
        this.setUsers(response.data);
        this.setPrintAnswersCode(response.data);
        this.setLoading(false);
      })
      .catch(error => {
        this.setError(true);
        this.setLoading(false);
        console.error('Ошибка при получении данных:', error);
      });
  }

  getStatisticCourse(id: number) {
    axios
      .get(`${BASE_URL}/api/v1/admin/entrance/export/${id}`, {
        headers: {
          Authorization: `Bearer ${userStore.token}`,
        },
      })
      .then(response => {
        this.setUsersInTableCourse(response.data.users);
      });
  }

  getTask(id_task?: string) {
    axios
      .get(`${BASE_URL}/api/v1/course/task/${id_task}`, {
        headers: {
          Authorization: `Bearer ${userStore.token}`,
        },
      })
      .then(response => {
        this.setCourseTask(response.data);
        this.setUserCode(response.data.code_area);
      })
      .catch(error => {
        console.error(`Ошибка при получении данных: ${error}`);
      });
  }

  getUserTask(user_id: number, task_id: string | undefined) {
    axios
      .get(`${BASE_URL}/api/v1/admin/course/task/users/${user_id}/${task_id}`, {
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
      })
      .then(response => {
        this.setCourseTask(response.data);
        this.setUserCode(response.data.code_area);
      })
      .catch(error => {
        this.setError(true);
        console.error(`Ошибка при получении данных: ${error}`);
      });
  }

  createAnswer(selectedChoices: number[], task_id: number, total_time: number) {
    this.setLoading(true);
    axios
      .post(
        `${BASE_URL}/api/v1/course/task/check/${task_id}`,
        { choices: selectedChoices, total_time: total_time },
        { headers: { Authorization: `Bearer ${userStore.token}` } },
      )
      .then(response => {
        this.setConsoleOutput(response.data);
        this.setLoading(false);
        this.setAccess(response.data.flag);
        this.setCompletedTask(response.data.message);
      })
      .catch(error => {
        this.setLoading(false);
        this.setError(true);
        console.error(error);
      });
  }

  postCode(id?: string, total_time?: number) {
    this.setLoading(true);
    axios
      .post(
        `${BASE_URL}/api/v1/sandbox/${this.courseTask.language_type}`,
        {
          task_id: id,
          code_area: this.userCode,
          total_time: total_time,
        },
        {
          headers: {
            Authorization: `Bearer ${this.token}`,
          },
        },
      )
      .then(response => {
        this.setConsoleOutput(response.data);
        this.setLoading(false);
      })
      .catch(error => {
        this.setLoading(false);
        this.setError(true);
        console.error(`Ошибка при получении данных: ${error}`);
      });
  }

  getCourseStatistics(course_id?: string) {
    axios
      .get(BASE_URL + '/api/v1/admin/entrance/export/' + course_id, {
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
      })
      .then(response => {
        this.setStatisticsCourse(response.data);
      });
  }

  getStatisticsCourse(course_id?: string) {
    fetch(BASE_URL + '/api/v1/admin/entrance/export_xls/' + course_id, {
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    })
      .then(res => res.blob())
      .then(response => {
        const url = URL.createObjectURL(response);
        const link = document.createElement('a');
        link.href = url;
        link.download =
          'Статистика' + this.statisticsCourse.course_name + '.xls';
        document.body.appendChild(link);
        link.style.display = 'none';
        link.click();
        link.remove();
        URL.revokeObjectURL(url);
      });
  }
}

export const coursesStore = new CourseStore();
