import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';
import { type DocumentationI } from 'Interfaces/coursesInterfaces';
import Python from './img/Python.png';
import JavaScript from './img/JS.png';
import styles from './TitleDocumetation.module.scss';

const TitleDocumentation = observer((data: DocumentationI) => {
  const navigate = useNavigate();
  return (
    <section className={styles.title__documentation}>
      <div className={styles.information__course__block}>
        <h2 className={styles.name__course}>{data.name}</h2>
        <div className={styles.description__course}>
          <h3>Описание</h3>
          <p className={styles.description}>{data.description}</p>
        </div>
        <button
          className={styles.title__documentation_btn}
          onClick={() => navigate(`/course/${data.id}`)}
        >
          Начать курс
        </button>
      </div>
      <img src={data.name === 'Python' ? Python : JavaScript} alt={'logo'} />
    </section>
  );
});

export default TitleDocumentation;
