import { makeAutoObservable } from 'mobx';
import { WEBSOCKET_MSG } from '../Const';
import { userStore } from './UserStore';
import { chatStore } from './ChatStore';

class WebsocketStore {
  socket?: WebSocket;

  constructor() {
    makeAutoObservable(this);
  }

  sendMessage(type?: string, data?: string | null | number, id?: number) {
    const message = JSON.stringify({ type: type, data: data, id: id });
    this.socket?.send(message);
  }

  connectWS() {
    this.socket = new WebSocket('wss://pre-dev.course.alabuga.space/ws/');
    this.socket.onopen = () => {
      if (this.socket?.readyState === WebSocket.OPEN) {
        userStore.setOnline(true);
        console.log('Connection opened');
        this.sendMessage(
          WEBSOCKET_MSG.TOKEN,
          localStorage.getItem('user_token'),
        );
      }
    };

    this.socket.onmessage = response => {
      const res = JSON.parse(response.data);
      const { type, data, message } = res;
      if (type === WEBSOCKET_MSG.NOTIFICATION) {
        userStore.setNotification(data.notifications);
      } else if (type === WEBSOCKET_MSG.ANOTHER_PROFILE) {
        userStore.setStarted_courses_another_user(data.courses);
        userStore.setAnotherProfile(data);
      } else if (type === WEBSOCKET_MSG.USER_CHATS) {
        chatStore.setUserChats(data);
      } else if (type === WEBSOCKET_MSG.MESSAGE_CHAT) {
        if (
          chatStore.chat.interlocutor?.id == message.sender.id ||
          userStore.user_header.id == message.sender.id
        ) {
          chatStore.chat?.chat?.push(message);
        }
      } else if (type === WEBSOCKET_MSG.CHAT) {
        chatStore.setChat(data);
      } else if (type === WEBSOCKET_MSG.DELETE_MESSAGE) {
        chatStore.chat.chat.splice(chatStore.indexUpdateMessage, 1);
        this.sendMessage(WEBSOCKET_MSG.USER_CHATS);
      } else if (type === WEBSOCKET_MSG.UPDATE_MESSAGE) {
        chatStore.pushUpdateMessage(data.text);
      } else if (type === WEBSOCKET_MSG.DELETE_CHAT) {
        chatStore.pushIndexUpdateUsers(data.id);
        chatStore.user_chats.splice(chatStore.indexUpdateUsers, 1);
        chatStore.setCastomContextMenuUserVis(false);
      }
    };

    this.socket.onerror = data => {
      console.log(data);
    };

    this.socket.onclose = () => {
      console.log('Connection closed');
      userStore.setOnline(false);
    };
  }
}
export const websocketStore = new WebsocketStore();
