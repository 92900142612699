import { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { coursesStore, userStore } from 'Stores/index';
import { Button, Spinner } from 'components/index';
import Editor from './components/Editor/Editor';
import styles from './taskPage.module.scss';

const TaskPage = observer(() => {
  const { user_header } = userStore;
  const { course_id, task_id, user_id } = useParams();
  const navigate = useNavigate();
  const [elapsedTime, setElapsedTime] = useState<number>(0);
  const { consoleOutput, courseTask } = coursesStore;
  const { message } = consoleOutput;

  useEffect(() => {
    const startTimeStr = localStorage.getItem(`startTime-${task_id}`);
    if (!startTimeStr) {
      const now = Date.now().toString();
      localStorage.setItem(`startTime-${task_id}`, now);
    }
    if (user_header.is_staff && window.location.href.includes('user')) {
      coursesStore.getUserTask(Number(user_id), task_id);
    } else {
      coursesStore.getTask(task_id);
    }
    coursesStore.setConsoleOutput({});
    document.title = 'Задача';
    window.scrollTo(0, 0);
  }, [window.location.href, task_id, user_id]);

  if (coursesStore.courseTask.flag === 'false') {
    navigate('/');
  }

  const completeTask = async () => {
    const startTimeStr = localStorage.getItem(`startTime-${task_id}`);
    if (startTimeStr) {
      const startTime = parseInt(startTimeStr, 10);
      const now = Date.now();
      const totalTime = Math.floor((now - startTime) / 1000);
      try {
        await coursesStore.postCode(task_id, totalTime);
        localStorage.removeItem(`startTime-${task_id}`);
      } catch (error) {
        console.error('Ошибка при завершении задачи:', error);
      }
    } else {
      await coursesStore.postCode(task_id);
    }
  };

  const nextTaskRedirect = () => {
    if (message?.success) {
      coursesStore.getTask(courseTask.next_task_id);
      if (courseTask.next_task_type === 'Practice') {
        navigate(`/course/${course_id}/task/${courseTask.next_task_id}`);
      } else if (courseTask.next_task_type === 'Theory') {
        navigate(`/course/${course_id}/testing/${courseTask.next_task_id}`);
      }
    } else {
      navigate(`/course/${course_id}/task/${task_id}`);
    }
  };

  return (
    <section className={styles.compiler}>
      <div className="container">
        <div className={styles.compiler__content__wrapper}>
          <div className={styles.compiler__task__theory}>
            <h2 className={styles.compiler__task__theory__title}>
              {courseTask.name_task}
            </h2>
            {courseTask.theory && (
              <div
                className={styles.compiler__task__theory__text}
                dangerouslySetInnerHTML={{
                  __html: courseTask.theory,
                }}
              />
            )}
            <Link to={`/course/${course_id}`} className={styles.compiler__btn}>
              Назад
            </Link>
          </div>

          <div className={styles.compiler__wrapper}>
            <div className={styles.compiler__user__code}>
              <Editor language={courseTask.language_type} />
            </div>
            <div className={styles.compiler__console}>
              <div className={styles.compiler__console__title__wrapper}>
                <p className={styles.compiler__console__title}>Консоль</p>

                <div className={styles.buttons__wrapper}>
                  <Button
                    text={coursesStore?.loading ? <Spinner /> : 'Проверить'}
                    onClick={completeTask}
                    className={styles.check}
                  />
                  <Button
                    text="Следующий"
                    onClick={nextTaskRedirect}
                    className={styles.check}
                  />
                </div>
              </div>
              <hr />
              <div className={styles.compiler__console__text}>
                <p
                  className={`${styles.compiler__console__result} ${message?.success ? styles.success : styles.failed}`}
                >
                  {message?.result}
                </p>
                {message?.output?.map((text, index) => {
                  return <p key={index}>{text}</p>;
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
});

export default TaskPage;
