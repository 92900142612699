import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { coursesStore } from 'Stores/CourseStore';
import { HeadCourse } from 'pages/Home/components/TableCourse/HeadCourse/HeadCourse';
import { TableItem } from 'pages/Home/components/TableItem/TableItem';
import styles from './TableCourse.module.scss';

export const TableCourse = observer(() => {
  useEffect(() => {
    coursesStore.getStatisticCourse(Number(localStorage.getItem('course')));
  }, []);

  const exitFromTable = () => {
    coursesStore.setIsOpenTableCourse(false);
    coursesStore.isOpenCourse = -1;
  };

  return (
    <div className={styles.table__bg} onClick={() => exitFromTable()}>
      <div className={styles.table} onClick={e => e.stopPropagation()}>
        <p className={styles.table__close} onClick={() => exitFromTable()}>
          Закрыть
        </p>
        <HeadCourse />
        <div className={styles.table__body}>
          {coursesStore.usersInTableCourse?.map((user, index) => (
            <TableItem key={index} {...user} />
          ))}
        </div>
      </div>
    </div>
  );
});
