import { useEffect } from 'react';
import { userStore } from 'Stores/UserStore';
import logo from '../../style/img/LogoAlabuga.svg';
import styles from './Loading.module.scss';

export default function Loading() {
  return (
    <div className={styles.loading}>
      <img src={logo} alt="logo" />
    </div>
  );
}
