import { type EntranceInputI } from 'Interfaces/userInterfaces';
import { userStore } from 'Stores/UserStore';
import { entranceStore } from 'Stores/EntranceStore';
import { values } from 'mobx';
import {
  personIcon,
  emailIcon,
  openPasswordIcon,
  closePasswordIcon,
  lockIcon,
} from '../img/index';

const { email, password, password2, lastName, firstName } = entranceStore;

export const REG_INPUTS: EntranceInputI[] = [
  {
    field: firstName,
    logo: personIcon,
    label: 'Имя',
    placeholder: 'Введите имя',
    onChange: (value: string) => entranceStore.setFirstName(value),
  },
  {
    field: lastName,
    logo: personIcon,
    label: 'Фамилия',
    placeholder: 'Введите фамилию',
    onChange: (value: string) => entranceStore.setLastName(value),
  },
  {
    field: email,
    logo: emailIcon,
    label: 'Электронный адрес',
    placeholder: 'example@mail.ru',
    onChange: (value: string) => entranceStore.setEmail(value),
  },
  {
    field: password,
    logo: lockIcon,
    label: 'Пароль',
    placeholder: 'Придумайте пароль',
    onChange: (value: string) => entranceStore.setPassword(value),
    icon: true,
    password: true,
  },
  {
    field: password2,
    logo: lockIcon,
    label: 'Подтвердите пароль',
    placeholder: 'Введите пароль еще раз',
    onChange: (value: string) => entranceStore.setPassword2(value),
    icon: true,
    password: true,
  },
];

export const REG_TXT = {
  title: 'Alabuga Courses',
  preTitle: 'Здесь рождаются специалисты мирового уровня',
  regTitle: 'Регистрация',
  regBtn: 'Зарегистрироваться',
  authTxt: 'Уже есть аккаунт? ',
  authLink: 'Авторизуйтесь!',
  regError: 'Произошла ошибка при регистрации!',
};
