import { CreateCourseBtn } from 'pages/CreateCourse/components/btns/createCouseBtn';
import { createCoursesStore } from 'Stores/CreateCourseStore';
import { observer } from 'mobx-react-lite';
import styles from './LessonPath.module.scss';

export const LessonPath = observer(() => {
  const { createdCourse, selectLesson } = createCoursesStore;

  return (
    <div className={styles.lessons}>
      <h1 className={styles.name}>Уроки</h1>
      <div className={styles.cyrcle_counter}>
        <CreateCourseBtn
          type="add"
          onClick={() => createCoursesStore.addLesson()}
        />
        <CreateCourseBtn
          type="delete"
          onClick={() => createCoursesStore.deleteLesson()}
        />

        {createdCourse.lesson?.map((_, index) => (
          <CreateCourseBtn
            key={`lesson-${index}`}
            type="normal"
            onClick={() => createCoursesStore.setSelectLesson(index)}
            number={index + 1}
            selected={selectLesson === index}
          />
        ))}
      </div>
      <input
        onChange={e => createCoursesStore.changeLessonName(e)}
        placeholder={'Название урока'}
        className={styles.title}
        value={createdCourse.lesson[selectLesson]?.name_lesson || ''}
      />
    </div>
  );
});
