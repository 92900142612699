import { createCoursesStore } from 'Stores/CreateCourseStore';
import { type FC } from 'react';
import styles from './choice.module.scss';

interface ChoiceI {
  value?: string;
  index: number;
  is_correct?: boolean;
}

export const Choice: FC<ChoiceI> = ({ value, index, is_correct }) => {
  return (
    <span className={styles.span}>
      <input
        className={styles.is_correct}
        type={'checkbox'}
        checked={is_correct}
        onChange={event =>
          createCoursesStore.changeStatusForChoice(index, event)
        }
      />
      <input
        className={styles.title}
        placeholder="Bариант ответа"
        onChange={event => createCoursesStore.changeTextForChoice(index, event)}
        value={value}
      />
    </span>
  );
};
