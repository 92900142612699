import { observer } from 'mobx-react-lite';
import { type DocumentationI } from 'Interfaces/coursesInterfaces';
import styles from './SideCart.module.scss';

const SideCart = observer((data: DocumentationI) => {
  return (
    <div className={styles.sides__cart}>
      <h4>{data.name}</h4>
      <p>{data.description}</p>
    </div>
  );
});

export default SideCart;
