import { HTMLInputTypeAttribute } from 'react';
import { type ChoicesI, type StartedCoursesI } from './coursesInterfaces';

export interface AchievementI {
  id: number;
  name: string;
  achievement_photo: string | null;
  description: string;
  index?: number;
}

export interface TopUserI {
  place: number;
  avatar_photo: File | null;
  first_name: string;
  last_name: string;
  rank: string;
  exp: number;
  id: number;
}

export interface InfoDataCourseI {
  id: number;
  avatar_photo: File | string | null;
  first_name: string;
  last_name: string;
}

export interface RatingI {
  top_users: TopUserI[];
  current_user: TopUserI;
}

export interface NotificationsI {
  created_at: string;
  notification_photo: string;
  text: string;
  title: string;
  type: string;
}

export interface UserI {
  first_name?: string;
  last_name?: string;
  user_image?: string;
  is_staff?: boolean;
  is_student?: boolean;
  exp?: number;
  avatar_photo?: string;
  id?: number;
  notifications?: NotificationsI[];
}

export interface UserTableI {
  id?: number;
  first_name: string;
  last_name: string;
  email: string;
  avatar_photo: string | File;
}

export interface UserTableCourseI {
  id?: number;
  first_name: string;
  last_name: string;
  avatar_photo: string | File;
}

export interface TableUsersI {
  id: number;
  code_area: string;
  project_file: File | null;
  user: UserTableI;
  answers: ChoicesI[];
  total_time: number;
}

export interface TableCourseI {
  id: number;
  first_name: string;
  last_name: string;
  avatar_photo: string | File;
  task_count: number;
  completed_task_count: number;
  completion_percentage: string;
  email: string;
  total_time: number;
}

export interface UserFullI {
  rank?: string;
  email?: string;
  achievements?: AchievementI[];
  started_course?: StartedCoursesI[];
}

export interface AnotherUserI {
  rank?: string;
  achievement?: AchievementI[];
  courses?: StartedCoursesI[];
  first_name: string;
  last_name: string;
  email: string;
  exp?: number;
  avatar_photo?: string;
  id?: number;
  status?: boolean;
  is_student?: boolean;
}

export interface EntranceInputI {
  placeholder: string;
  logo: string;
  label: string;
  field: string;
  onChange: (value: string) => void;
  icon?: boolean;
  password?: boolean;
}

export enum Languages {
  Python = 'python',
  JavaScript = 'javascript',
}

export enum Complexities {
  Easy = 'Easy',
  Medium = 'Medium',
  Hard = 'Hard',
}

export enum Task_types {
  Practice = 'Practice',
  Theory = 'Theory',
  Project = 'Project',
}

export enum Type_choices {
  Checkbox = 'checkbox',
  Radio = 'radio',
}
