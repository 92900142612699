import { Complexities, Languages, Task_types } from 'Interfaces/userInterfaces';

export const task_types = [
  { value: Task_types.Practice, label: 'Практическая работа' },
  { value: Task_types.Theory, label: 'Теоретические задачи' },
  { value: Task_types.Project, label: 'Проектная работа' },
];
export const complexities = [
  { value: Complexities.Easy, label: 'Простой' },
  { value: Complexities.Medium, label: 'Средний' },
  { value: Complexities.Hard, label: 'Тяжелый' },
];

export const languages = [
  { value: Languages.Python, label: Languages.Python },
  {
    value: Languages.JavaScript,
    label: Languages.JavaScript,
  },
];
