import { type CartNavigateI } from 'Interfaces/helpInterfaces';

export const NAVIGATE_HEADER_USER: CartNavigateI[] = [
  {
    title: 'Все курсы',
    path: '/',
  },
  {
    title: 'FAQ',
    path: '/faq',
  },
];

export const NAVIGATE_HEADER_ADMIN: CartNavigateI[] = [
  {
    title: 'Все курсы',
    path: '/',
  },
  {
    title: 'Создать курс',
    path: '/create_course',
  },
  {
    title: 'Рейтинг',
    path: '/rating',
  },
  {
    title: 'FAQ',
    path: '/faq',
  },
];
