import { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { coursesStore } from 'Stores/CourseStore';
import { createCoursesStore } from 'Stores/CreateCourseStore';
import { type CourseCuttedI } from 'Interfaces/coursesInterfaces';
import { Button, Course } from 'components/index';
import { CreateCourseModal } from 'pages/CreateCourse/components/CreateCourseTable/CreateCourseModal';
import plus from 'style/img/plus-course.svg';
import styles from './createCourse.module.scss';

export const CreateCourse = observer(() => {
  useEffect(() => {
    coursesStore.getCourses();
    document.title = 'Создать курс';
  }, []);

  return (
    <div className={styles.courses}>
      <div className="container">
        <section className={styles.courses__block}>
          <ul className={styles.courses__list}>
            <div className={styles.course}>
              <h1>Новый курс</h1>
              <div className={styles.plus}>
                <img src={plus} />
              </div>
              <Button
                text={'Создать курс'}
                className={styles.btn}
                onClick={() =>
                  createCoursesStore.setIsOpenCreateCourseTable(true)
                }
              />
            </div>

            {coursesStore.coursesList.map((data: CourseCuttedI) => {
              return <Course key={data.id} {...data} />;
            })}
          </ul>
        </section>
      </div>
      {createCoursesStore.isOpenCreateCourseTable && <CreateCourseModal />}
    </div>
  );
});
