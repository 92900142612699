import userLogo from 'style/img/UserIcon.svg';
import { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { CompletedCourse, Achievement, Button } from 'components/index';
import { adminStore, userStore } from 'Stores/index';
import { BASE_URL } from 'consturl';
import { type CompletedCourseI, type AchievementI } from 'Interfaces/index';
import { InfoBlock } from 'pages/Profiles/MyProfile/components/infoBlock/InfoBlock';
import styles from './AnotherProfile.module.scss';

const AnotherProfile = observer(() => {
  const { id } = useParams();

  useEffect(() => {
    userStore.getAnotherProfile(Number(id));
  }, []);

  return (
    <section className={styles.user__profile__wrapper}>
      <div className={styles.user__profile__content__wrapper}>
        <div className={styles.user__profile}>
          <div className={styles.userinfo__form}>
            <div className={styles.user__avatar_wrapper}>
              <h1>Профиль</h1>
              <div className={styles.info__block}>
                <img
                  className={styles.avatar}
                  src={
                    userStore.AnotherProfile?.avatar_photo
                      ? `${BASE_URL}${userStore.AnotherProfile?.avatar_photo}`
                      : userLogo
                  }
                  alt="user-photo"
                />
                <div className={styles.info__block_text}>
                  <p className={styles.user__rank}>
                    {userStore.AnotherProfile?.rank}
                  </p>
                  <p className={styles.user__exp}>
                    {userStore.AnotherProfile?.exp} XP
                  </p>
                </div>
              </div>
            </div>

            <InfoBlock
              info={userStore.AnotherProfile?.first_name}
              title={'Имя'}
            />
            <InfoBlock
              info={userStore.AnotherProfile?.last_name}
              title={'Фамилия'}
            />
            <InfoBlock info={userStore.AnotherProfile?.email} title={'Email'} />

            <label className={styles.label}>
              <input
                type={'checkbox'}
                className={styles.input__checkbox}
                checked={userStore.AnotherProfile?.is_student}
                disabled
              />{' '}
              Я студент Алабуги
            </label>

            <p>
              Для смены информации перейдите на
              <Link to={'http://is.hr.alabuga.ru'} className={styles.link}>
                {' '}
                HR-платформу
              </Link>
            </p>
            {userStore.user_header.is_staff && (
              <Button
                text={'Заблокировать'}
                className={styles.btn}
                onClick={() => adminStore.handleBanUser(Number(id))}
              />
            )}
          </div>

          <div className={styles.user__courses_block}>
            <h1>Курсы</h1>
            {userStore.AnotherProfile?.courses?.length ? (
              <ul className={styles.user__started__courses__list}>
                {userStore.AnotherProfile?.courses.map(
                  (data: CompletedCourseI) => (
                    <CompletedCourse {...data} key={data.id} />
                  ),
                )}
              </ul>
            ) : (
              <h2 className={styles.not__courses}>Курсов еще нет</h2>
            )}
          </div>
        </div>
      </div>
      <div className={styles.user__achievements__wrapper}>
        <h1 className={styles.title}>Достижения</h1>
        <div className={styles.user__achievements__list}>
          {userStore.AnotherProfile?.achievement?.length ? (
            userStore.AnotherProfile?.achievement?.map((data: AchievementI) => (
              <Achievement {...data} key={data.name} />
            ))
          ) : (
            <h1 className={styles.user__achievements_none}>Нет достижений</h1>
          )}
        </div>
      </div>
    </section>
  );
});

export default AnotherProfile;
