import { observer } from 'mobx-react-lite';
import { coursesStore } from 'Stores/index';
import styles from './previewSection.module.scss';

const PreviewSection = observer(() => {
  return (
    <section className={styles.course__description__wrapper}>
      <div
        className={`${styles.course__description__content__wrapper} container`}
      >
        <div className={styles.course__description}>
          <h2 className={styles.course__description__title}>
            {coursesStore.course.name_course}
          </h2>
          <div
            className={styles.course__description__text}
            dangerouslySetInnerHTML={{
              __html: coursesStore.course.detailed_description,
            }}
          />
        </div>
        <p className={styles.lessons}>
          {coursesStore.course.lesson.length} уроков
        </p>
      </div>
    </section>
  );
});

export default PreviewSection;
