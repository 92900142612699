import { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Loading } from 'components/index';
import { coursesStore, userStore } from 'Stores/index';
import { TableCourse } from 'pages/Home/components/TableCourse/TableCourse';
import PreviewSection from './components/PreviewSection/PrewiewSection';
import CoursesSection from './components/CoursesSection/CoursesSection';

const Home = observer(() => {
  useEffect(() => {
    coursesStore.getCourses();
    document.title = 'Главная';
    coursesStore.courseTask.flag = '';
  }, []);

  return (
    <>
      {userStore.loading ? (
        <Loading />
      ) : (
        <>
          <PreviewSection />
          <CoursesSection />
          {coursesStore.isOpenTableCourse && <TableCourse />}
        </>
      )}
    </>
  );
});

export default Home;
