import arrow from '../../img/errorArrow.svg';
import style from './text.module.scss';

export const ErrorComponent = () => {
  return (
    <div className={style.block}>
      <div className={style.block__border_error}>
        <img src={arrow} alt={'error'} />
      </div>
      <p className={style.choice__text_reject}>Упс, вы совершили ошибку</p>
    </div>
  );
};
