import { observer } from 'mobx-react-lite';
import { type EntranceInputI } from 'Interfaces/userInterfaces';
import { Button } from 'components/index';
import { Link, useNavigate } from 'react-router-dom';
import { entranceStore } from 'Stores/EntranceStore';
import { type FormsLogI } from 'Interfaces/helpInterfaces';
import EntranceInput from 'pages/Entrance/components/EntranceInput/EntranceInput';
import { LOG_TXT, LOG_INPUTS } from '../../LogMock/LogMock';

import styles from './LogSidebar.module.scss';

export const LogSidebar = observer(() => {
  const navigate = useNavigate();
  const { logTitle, logBtn, regTxt, regLink, loginError } = LOG_TXT;
  const { email, password } = entranceStore;
  const form: FormsLogI = {
    email: email,
    password: password,
  };

  return (
    <div className={styles.sidebar}>
      <div className={styles.sidebar__form}>
        <h2>{logTitle}</h2>
        {LOG_INPUTS.map((input: EntranceInputI, index) => {
          return <EntranceInput {...input} key={index} />;
        })}
        <Button
          text={logBtn}
          className={styles.log__btn}
          onClick={() => entranceStore.login(form, navigate)}
        />
        {entranceStore.loginError && (
          <p className={styles.register__text_error}>{loginError} </p>
        )}
        <div className={styles.register}>
          <p className={styles.register__text}>{regTxt} </p>
          <Link
            to="/register"
            onClick={() => entranceStore.setLoginError(false)}
            className={styles.register__link}
          >
            {regLink}
          </Link>
        </div>
      </div>
    </div>
  );
});
