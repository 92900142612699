import { coursesStore } from 'Stores/CourseStore';
import { BASE_URL } from 'consturl';
import userLogo from 'style/img/UserIcon.svg';
import styles from './UserData.module.scss';

export const UserData = () => {
  return (
    <div className={styles.user__info_block}>
      <img
        className={styles.user__avatar}
        src={
          coursesStore.course.user_data?.avatar_photo
            ? `${BASE_URL}${coursesStore.course.user_data?.avatar_photo}`
            : userLogo
        }
        alt="user-photo"
      />
      <h1 className={styles.user__name}>
        {coursesStore.course.user_data?.first_name}
      </h1>
      <h1 className={styles.user__name}>
        {coursesStore.course.user_data?.last_name}
      </h1>
    </div>
  );
};
