import { useEffect } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { chatStore, userStore } from 'Stores/index';
import { websocketStore } from 'Stores/WebsocketStore';
import {
  Admin,
  CoursePage,
  Home,
  JavaScript,
  Login,
  Profile,
  Python,
  Rating,
  Register,
  TaskPage,
  UserCoursePage,
  TestPage,
} from 'pages/index';
import AnotherProfile from 'pages/Profiles/AnotherProfile/AnotherProfile';
import './style/scss/app.scss';
import StatisticsCourse from 'pages/StatisticsCourse/StatisticsCourse';
import Header from 'components/Header/Header';
import FAQ from 'pages/Faq/Faq';
import { NotificationsTable } from 'pages/Home/components/NotificationsTable/NotificationsTable';
import { CreateCourse } from 'pages/CreateCourse/CreateCourse';

const App = observer(() => {
  useEffect(() => {
    if (localStorage.getItem('user_token')) {
      websocketStore.connectWS();
      userStore.getUserHeader();
    }
    if (!localStorage.getItem('user_theme_color')) {
      localStorage.setItem('user_theme_color', 'light');
      userStore.setUserSiteTheme('light');
    }
  }, [localStorage.getItem('user_token')]);

  return (
    <>
      <main
        className={
          userStore.userSiteTheme === 'dark' ? 'dark__body' : 'light__body'
        }
        onClick={() => {
          chatStore.setSettings(0);
        }}
      >
        <BrowserRouter>
          {userStore.token && <Header />}
          <Routes>
            {!userStore.token ? (
              // Если токена нет, перенаправляем на страницу регистрации
              <>
                <Route path="/login" element={<Login />} />
                <Route path="/register" element={<Register />} />
                <Route path="*" element={<Navigate to="/login" />} />
              </>
            ) : (
              // Если токен есть, отображаем маршруты для авторизованных пользователей
              <>
                {userStore.user_header.is_staff && (
                  <>
                    <Route path="/create_course" element={<CreateCourse />} />
                    <Route path="/rating" element={<Rating />} />
                    <Route path="/administration" element={<Admin />} />
                    <Route
                      path="/course/:course_id/user/:user_id"
                      element={<UserCoursePage />}
                    />
                    <Route
                      path="/course/:course_id/statistics"
                      element={<StatisticsCourse />}
                    />
                    <Route path="/profile/:id" element={<AnotherProfile />} />
                    <Route
                      path="/course/:course_id/user/:user_id/testing/:task_id"
                      element={<TestPage />}
                    />
                    <Route
                      path="/course/:course_id/user/:user_id/task/:task_id"
                      element={<TaskPage />}
                    />
                  </>
                )}
                <Route path="*" element={<Navigate to="/" />} />
                <Route
                  path="/"
                  element={<Home />}
                  errorElement={<h1>Ошибка</h1>}
                />
                <Route path="/profile" element={<Profile />} />
                <Route path="/course/:course_id" element={<CoursePage />} />
                <Route
                  path="/course/:course_id/task/:task_id"
                  element={<TaskPage />}
                />
                <Route
                  path="/course/:course_id/testing/:task_id"
                  element={<TestPage />}
                />
                <Route path="/python" element={<Python />} />
                <Route path="/javascript" element={<JavaScript />} />
                <Route path="/faq" element={<FAQ />} />
              </>
            )}
          </Routes>
          {userStore.isOpenNotifications && <NotificationsTable />}
        </BrowserRouter>
      </main>
    </>
  );
});

export default App;
