import upload_icon from 'style/img/upload-icon.svg';
import { observer } from 'mobx-react-lite';
import styles from './DropMenu.module.scss';

export const DropMenu = observer(() => {
  return (
    <div onClick={e => e.stopPropagation()}>
      <input className={styles.input} type={'file'} id={'file'} />
      <label htmlFor={'file'} className={styles.label}>
        <img src={upload_icon} className={styles.upload} />
        <p className={styles.upload__title}>Нажмите или перетащите файлы</p>
        <p className={styles.upload__text}>
          Поддерживаемый формат файла: rar, 7z, zip
        </p>
        <p className={styles.upload__text}>Загружайте не более 2-ух файлов</p>
      </label>
    </div>
  );
});
