import { observer } from 'mobx-react-lite';
import { Course } from 'components/index';
import { coursesStore } from 'Stores/CourseStore';
import { type CourseCuttedI } from 'Interfaces/coursesInterfaces';
import { HOME_TXT } from 'pages/Home/constant';
import styles from './coursesSection.module.scss';

const CoursesSection = observer(() => {
  const { titleCourse } = HOME_TXT;
  return (
    <div className={styles.courses}>
      <div className="container">
        <section className={styles.courses__block}>
          <h2 className={styles.courses__block__title}>{titleCourse}</h2>
          <ul className={styles.courses__list}>
            {coursesStore.coursesList.map((data: CourseCuttedI) => {
              return <Course key={data.id} {...data} />;
            })}
          </ul>
        </section>
      </div>
    </div>
  );
});

export default CoursesSection;
