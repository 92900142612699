import { observer } from 'mobx-react-lite';
import { type CartNavigateI } from 'Interfaces/helpInterfaces';
import cn from 'classnames';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { userStore } from 'Stores/UserStore';
import styles from './CartNavigate.module.scss';

const CartNavigate = observer(({ title, path }: CartNavigateI) => {
  const navigate = useNavigate();
  const [active, setActive] = useState(false);

  useEffect(() => {
    const pathName = window.location.pathname;
    if (pathName == path) {
      setActive(true);
    } else {
      setActive(false);
    }
  }, [window.location.pathname, window.location.hostname]);

  const navigateHandle = () => {
    navigate(path);
    userStore.setModalPlatformVis(false);
  };

  return (
    <div
      className={cn(styles.cart__navigate, {
        [styles.active]: active,
      })}
      onClick={navigateHandle}
    >
      <p className={styles.title}>{title}</p>
    </div>
  );
});

export default CartNavigate;
