import { observer } from 'mobx-react-lite';
import HeaderChat from 'pages/Chat/components/HeaderChat/HeaderChat';
import { chatStore } from 'Stores/ChatStore';
import { type ChatMessageI } from 'Interfaces/chatinterface';
import Message from 'pages/Chat/components/Message/Message';
import SendMessage from 'pages/Chat/components/SendMessage/SendMessage';
import { useEffect } from 'react';
import styles from './MessagePanel.module.scss';

const MessagePanel = observer(() => {
  useEffect(() => {
    const hr_scroll: HTMLElement | null = document.getElementById('hr');
    if (hr_scroll) {
      hr_scroll.scrollIntoView({ block: 'end', behavior: 'smooth' });
    }
  }, []);

  return (
    <div className={styles.chat}>
      <HeaderChat />
      <div className={styles.message__list}>
        {chatStore.chat.chat?.map((data: ChatMessageI, index) => {
          return <Message {...data} key={index} />;
        })}
        <hr id="hr" />
      </div>
      <SendMessage />
    </div>
  );
});

export default MessagePanel;
