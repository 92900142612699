import { observer } from 'mobx-react-lite';
import {
  type TaskCuttedI,
  type TaskStringDataI,
} from 'Interfaces/coursesInterfaces';
import { Task } from 'components/index';
import styles from './taskString.module.scss';

const TaskString = observer((data: TaskStringDataI) => {
  return (
    <div className={styles.lesson__tasks__block}>
      <div className={styles.lesson__tasks__list}>
        {data.task_string.task.map((task: TaskCuttedI) => {
          return <Task key={task.id} {...task} />;
        })}
      </div>
    </div>
  );
});

export default TaskString;
