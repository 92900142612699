import { Button } from 'components/index';
import icon_none from 'style/img/icon-none.svg';
import { createCoursesStore } from 'Stores/CreateCourseStore';
import { observer } from 'mobx-react-lite';
import { useRef } from 'react';
import styles from './CreateCourseHead.module.scss';

export const CreateCourseHead = observer(() => {
  const form = useRef<HTMLFormElement>(null);

  return (
    <form className={styles.head}>
      <input
        placeholder={'Название курса'}
        className={styles.title}
        onChange={e => createCoursesStore.addCourseName(e)}
        value={createCoursesStore.createdCourse?.name_course}
        name="name_course"
      />

      {/* <label className={styles.head_label}>Иконка курса</label>

      <label onClick={e => e.stopPropagation()}>
        <input
          className={styles.input}
          type="file"
          id="file"
          onChange={event => createCoursesStore.handleFileUpload(event)}
          name="preview_image"
        />
        <img src={icon_none} className={styles.head_img} alt="Preview" />

        <p>{createCoursesStore.picName}</p>
      </label> */}

      <Button
        text={'Опубликовать'}
        onClick={() => createCoursesStore.createCourse()}
        className={styles.head_btn}
        type="button"
      />
    </form>
  );
});
