import { observer } from 'mobx-react-lite';
import { websocketStore } from 'Stores/WebsocketStore';
import { chatStore } from 'Stores/ChatStore';
import { userStore } from 'Stores/UserStore';
import { useEffect } from 'react';
import MessagePanel from 'pages/Chat/components/MessagePanel/MessagePanel';
import SelectChat from 'pages/Chat/components/SelectChat/SelectChat';
import { WEBSOCKET_MSG } from '../../Const';
import LeftChatsPanel from './components/LeftChatsPanel/LeftChatsPanel';
import styles from './Chat.module.scss';

const Chat = observer(() => {
  return (
    <section className={styles.section__chat}>
      <div className={styles.content_section_chat}>
        <LeftChatsPanel />
        <div
          className={styles.chat__panel}
          onContextMenu={event => {
            event.preventDefault();
          }}
        >
          {chatStore.chat.interlocutor ? <MessagePanel /> : <SelectChat />}
        </div>
      </div>
    </section>
  );
});

export default Chat;
