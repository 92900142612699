import { Type_choices } from 'Interfaces/userInterfaces';

export const initialState = {
  preview_image: null,
  name_course: '',
  detailed_description: '',
  lesson: [
    {
      name_lesson: '',
      task_string: [
        {
          task: [
            {
              name_task: '',
              task_type: '',
              complexity: '',
              xp: 0,
              language_type: '',
              theory: '',
              name_func: '',
              auto_test: '',
              type_choices: Type_choices.Checkbox,
              original_documentation: '',
              choices: [],
            },
          ],
        },
      ],
    },
  ],
};

export const task_item = {
  name_task: '',
  task_type: '',
  complexity: '',
  xp: 0,
  language_type: '',
  theory: '',
  name_func: '',
  auto_test: '',
  type_choices: '',
  choices: [],
  original_documentation: '',
};

export const string_item = {
  task: [task_item],
};

export const lesson_item = {
  name_lesson: '',
  task_string: [string_item],
};

export const choice_item = { title: '', is_correct: false };
