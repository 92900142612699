import { CreateCourseBtn } from 'pages/CreateCourse/components/btns/createCouseBtn';
import { createCoursesStore } from 'Stores/CreateCourseStore';
import { useState } from 'react';
import { Choice } from '../choices/Choice';
import styles from './choicesBlock.module.scss';

export const ChoicesBlock = () => {
  const { createdCourse, selectString, selectLesson, selectTask } =
    createCoursesStore;

  const task =
    createdCourse.lesson[selectLesson]?.task_string[selectString]?.task[
      selectTask
    ];

  const [isChecked, setIsChecked] = useState<boolean>(false);

  return (
    <div className={styles.choices__block}>
      <label className={styles.label}>
        <CreateCourseBtn
          type="add"
          onClick={() => createCoursesStore.addChoice()}
        />
        <CreateCourseBtn
          type="delete"
          onClick={() => createCoursesStore.deleteChoice()}
        />
        Добавить вариант ответа
      </label>

      {task.choices?.map((_, index) => <Choice key={index} index={index} />)}

      {/* <label className={`${styles.label} ${styles.one}`}>
        <div className={styles.toggleSwitch}>
          <input
            type="checkbox"
            onChange={() => {
              createCoursesStore.changeTaskChioceType(!isChecked)
              setIsChecked(!isChecked)
            }}
            checked={isChecked}
          />
          <span className={styles.slider}></span>
        </div>
        <span>Тип ответов (radio/checkbox)</span>
      </label> */}
    </div>
  );
};
