import { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Button, Loading, Achievement } from 'components/index';
import { adminStore, coursesStore, userStore } from 'Stores/index';
import { type AchievementI } from 'Interfaces/userInterfaces';
import { type CourseCuttedI } from 'Interfaces/coursesInterfaces';
import Course from 'components/Courses/Course';
import styles from './Admin.module.scss';
import EditOrAddAchievementForm from './components/EditOrAddAchievementForm/EditOrAddAchievementForm';
import EditOrAddCourseForm from './components/EditOrAddCourseForm/EditOrAddCourseForm';

const Admin = observer(() => {
  const [allOrBannedUsers, setAllOrBannedUsers] = useState(true);
  const [searchText, setSearchText] = useState('');

  useEffect(() => {
    coursesStore.getCourses();
    adminStore.getAchievements();
    adminStore.getAllUsers();
    document.title = 'Админи-панель';
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {userStore.loading ? (
        <Loading />
      ) : (
        <section className={styles.admin__panel}>
          <div className={styles.admin__content__wrapper}>
            <div className={styles.admin__panel__title__wrapper}>
              <p className={styles.admin__panel__title}>Курсы</p>
              <Button
                text="Добавить курс"
                onClick={() => adminStore.addCourse()}
              />
              <hr />
            </div>

            {<EditOrAddCourseForm />}
            {adminStore.getNewTaskForm()}
            <div className={styles.courses__list}>
              {coursesStore.coursesList.map((data: CourseCuttedI) => {
                return <Course key={data.id} {...data} />;
              })}
            </div>
            <div className={styles.admin__panel__title__wrapper}>
              <p className={styles.admin__panel__title}>Достижения</p>
              <Button
                text="Добавить достижение"
                onClick={() => adminStore.addAchievement()}
              />
              <hr />
            </div>
            {<EditOrAddAchievementForm />}
            <ul className={styles.admin__panel__achievements__list}>
              {adminStore.achievementsList.map(
                (achievement: AchievementI, index: number) => {
                  return (
                    <Achievement key={index} {...achievement} index={index} />
                  );
                },
              )}
            </ul>
            <div className={styles.admin__panel__users__title}>
              <div className={styles.admin__panel__users__filter}>
                <p
                  className={
                    allOrBannedUsers
                      ? `${styles.admin__panel__users__filter__button} ${styles.active}`
                      : `${styles.admin__panel__users__filter__button}`
                  }
                  onClick={() => {
                    setAllOrBannedUsers(true);
                    adminStore.setIsSearch(false);
                  }}
                >
                  Все пользователи
                </p>
                <p
                  className={
                    allOrBannedUsers
                      ? `${styles.admin__panel__users__filter__button}`
                      : `${styles.admin__panel__users__filter__button} ${styles.active}`
                  }
                  onClick={() => {
                    setAllOrBannedUsers(false);
                    adminStore.setIsSearch(false);
                  }}
                >
                  Заблокированные
                </p>
              </div>
              <hr className={styles.hr__search__min} />
              <div className={styles.admin__panel__search}>
                <button
                  className={styles.admin__panel__search__button}
                  onClick={() =>
                    adminStore.search({ searchText, allOrBannedUsers })
                  }
                />
                <input
                  type="text"
                  value={searchText}
                  onChange={event => setSearchText(event.target.value)}
                  placeholder="Поиск"
                  className={styles.admin__panel__search__input}
                  onFocus={() => adminStore.setIsSearch(false)}
                />
              </div>
            </div>
            <hr className={styles.hr__search__max} />
            <ul className={styles.admin__panel__users__list}>
              {allOrBannedUsers ? (
                <div className={styles.banned__user__list}>
                  {adminStore.isSearch
                    ? adminStore.printUsers(adminStore.sortedUsersList)
                    : adminStore.printUsers(adminStore.allUsersList)}
                </div>
              ) : (
                <div className={styles.banned__user__list}>
                  {adminStore.isSearch
                    ? adminStore.printUsers(adminStore.sortedUsersList)
                    : adminStore.printUsers(adminStore.bannedUsersList)}
                </div>
              )}
            </ul>
          </div>
        </section>
      )}
    </>
  );
});
export default Admin;
