import { observer } from 'mobx-react-lite';
import { adminStore } from 'Stores/AdminStore';
import { type NewTaskI } from 'Interfaces/adminInterfaces';
import styles from './NewTask.module.scss';

const NewTask = observer((data: NewTaskI) => {
  const { lessonIndex, taskStringIndex, thisTaskIndex, thisTask } = data;

  return (
    <li className={styles.admin__new__task}>
      {adminStore.isEditCourse === false &&
        adminStore.isAddOrEditTask === false && (
        <div
          className={styles.admin__new__task__delete}
          onClick={() =>
            adminStore.deleteTask({
              lessonIndex,
              taskStringIndex,
              taskIndex: thisTaskIndex,
              })
          }
        />
      )}
      <p
        className={styles.new__task__name}
        onClick={() =>
          adminStore.setEditOrAddTaskForm({
            lessonIndex,
            taskStringIndex,
            taskIndex: thisTaskIndex,
            editableTask: thisTask,
          })
        }
      >
        {thisTask.name_task}
      </p>
    </li>
  );
});

export default NewTask;
