import { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { LogSidebar } from 'pages/Entrance/Login/components/RegSidebar/LogSidebar';
import { userStore } from 'Stores/index';
import PreviewSection from '../components/PreviewSection/PreviewSection';
import styles from './Login.module.scss';
import 'style/scss/app.scss';

const Login = observer(() => {
  useEffect(() => {
    document.title = 'Авторизация';
    window.scrollTo(0, 0);
    userStore.setSidebarVisible(true);
  }, []);

  return (
    <section className={styles.login}>
      <PreviewSection />
      <LogSidebar />
    </section>
  );
});

export default Login;
