import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { coursesStore } from 'Stores/index';
import { StatisticTable } from 'components/index';
import LessonSection from './components/LessonSection/LessonSection';
import PreviewSection from './components/PreviewSection/PreviewSection';

const CoursePage = observer(() => {
  const { course_id } = useParams();

  useEffect(() => {
    if (course_id) {
      coursesStore.getCourse(course_id);
    }
    document.title = 'Страница курса';
    window.scrollTo(0, 0);
    coursesStore.courseTask.flag = '';
  }, []);

  return (
    <>
      <PreviewSection />
      <LessonSection />
      {coursesStore.isOpenTable && <StatisticTable />}
    </>
  );
});
export default CoursePage;
