import axios from 'axios';
import { BASE_URL } from 'consturl';
import { type FormsLogI, type FormsRegI } from 'Interfaces/helpInterfaces';
import { makeAutoObservable, observable } from 'mobx';
import { type NavigateFunction } from 'react-router-dom';

class EntranceStore {
  lo =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // eslint-disable-line
  notvalid_pass = false;
  notvalid_email = false;
  notvalid_passes = false;
  notvalid_name = false;
  loading = false;
  loginError = false;
  error = false;
  firstName = '';
  lastName = '';
  password = '';
  password2 = '';
  email = '';

  constructor() {
    makeAutoObservable(this);
  }

  setEmail(value: string) {
    this.email = value;
  }

  setFirstName(value: string) {
    this.firstName = value;
  }

  setLastName(value: string) {
    this.lastName = value;
  }

  setPassword(value: string) {
    this.password = value;
  }

  setPassword2(value: string) {
    this.password2 = value;
  }

  setNotValidEmail(value: boolean) {
    this.notvalid_email = value;
  }

  setNotValidPass(value: boolean) {
    this.notvalid_pass = value;
  }

  setNotValidPasses(value: boolean) {
    this.notvalid_passes = value;
  }

  setNotValidName(value: boolean) {
    this.notvalid_name = value;
  }

  setLoading(value: boolean) {
    this.loading = value;
  }

  setLoginError(value: boolean) {
    this.loginError = value;
  }

  setError(value: boolean) {
    this.error = value;
  }

  register(form: FormsRegI, navigate: NavigateFunction) {
    axios
      .post(`${BASE_URL}/api/v1/register`, form)
      .then(response => {
        localStorage.setItem('user_token', response.data.data.user_token);
        this.setLoading(false);
        navigate('/login');
      })
      .catch(() => {
        this.setLoginError(true);
      });
  }

  login(form: FormsLogI, navigate: NavigateFunction) {
    this.setLoading(true);
    this.setError(false);
    if (this.lo.test(form.email)) {
      this.notvalid_email = false;
      axios
        .post(`${BASE_URL}/api/v1/login`, form)
        .then(response => {
          localStorage.setItem('user_token', response.data.data.user_token);
          navigate('/');
          window.location.reload();
          this.setLoading(false);
          this.setLoginError(false);
        })
        .catch(() => {
          this.setLoading(false);
          this.setError(true);
          this.setLoginError(true);
        });
    } else {
      this.setLoading(false);
      this.notvalid_email = true;
    }
  }
}

export const entranceStore = new EntranceStore();
