import { createCoursesStore } from 'Stores/CreateCourseStore';
import { CreateCourseBtn } from 'pages/CreateCourse/components/btns/createCouseBtn';
import { observer } from 'mobx-react-lite';
import { Task_types } from 'Interfaces/userInterfaces';
import { DropMenu } from '../DropMenu/dropMenu';
import styles from './TaskPath.module.scss';
import { DataMenu } from './components/DataMenu/DataMenu';

export const TaskPath = observer(() => {
  const { createdCourse, selectString, selectLesson, selectTask } =
    createCoursesStore;
  const task =
    createdCourse.lesson[selectLesson]?.task_string[selectString]?.task[
      selectTask
    ];

  return (
    <>
      <div className={styles.lessons}>
        <h1 className={styles.name}>Задачи</h1>
        <div className={styles.cyrcle_counter}>
          <CreateCourseBtn
            type="add"
            onClick={() => createCoursesStore.addTask()}
          />
          <CreateCourseBtn type="delete" />

          {createdCourse.lesson[selectLesson]?.task_string?.[
            selectString
          ]?.task?.map((_, index) => (
            <CreateCourseBtn
              key={index}
              type="normal"
              onClick={() => createCoursesStore.setSelectTask(index)}
              number={index + 1}
              selected={selectTask === index}
            />
          ))}
        </div>
      </div>
      <DataMenu />
      {task?.task_type === Task_types.Project && <DropMenu />}
    </>
  );
});
