import { makeAutoObservable } from 'mobx';
import { BASE_URL } from 'consturl';
import { type ChangeEvent, type ReactElement } from 'react';
import axios from 'axios';
import { type LessonI, type TaskI } from 'Interfaces/coursesInterfaces';
import AddNewTaskForm from 'pages/Admin/components/EditOrAddNewTaskForm/EditOrAddNewTaskForm';
import { userStore, coursesStore } from 'Stores/index';
import {
  type AllUsersListI,
  type ChangeTaskI,
  type CreateTaskI,
  type DeleteAchievementI,
  type EditAchievementI,
  type EditOrAddTaskFormI,
  type EditCourseI,
  type PublicNewCourseI,
  type DeleteTaskI,
  type DeleteTaskStringI,
  type PrintNewTasksI,
  type StartAddNewTaskI,
  type StartEditLessonI,
  type ChangeLessonTitleI,
  type BanUnbanUserI,
  type CreateOrChangeTaskI,
  type PublicNewAchievementI,
  type OptionI,
  type SearchI,
  type AchievementI,
} from 'Interfaces/index';
import UserBlock from 'pages/Admin/components/UserBlock/UserBlock';
import NewTask from 'pages/Admin/components/NewTask/NewTask';
import NewTaskString from 'pages/Admin/components/NewTaskString/NewTaskString';

class AdminStore {
  // Переменные
  // Блок пользователей
  allUsersList: AllUsersListI[] = [];
  bannedUsersList: AllUsersListI[] = [];
  sortedUsersList: AllUsersListI[] = [];
  isSearch = false;

  // Блок курса
  isAddCourse = false;
  isEditCourse = false;
  isAddOrEditTask = false;
  idEditableCourse = 0;
  name_course = '';
  detailed_description = '';

  // Вспомогательные функции и переменные
  arrayOfCompexities: OptionI[] = [
    { value: 'Easy', name: 'Easy' },
    { value: 'Medium', name: 'Medium' },
    { value: 'Hard', name: 'Hard' },
  ];
  arrayOfLanguageTypes: OptionI[] = [
    { value: 'python', name: 'python' },
    { value: 'javascript', name: 'javascript' },
  ];

  // Блок уроков
  newLessons: LessonI[] = [];
  newLessonTitle = '';
  editableLessonTitle = '';
  isAddLesson = false;

  // Блок задач
  editOrAddTaskForm: ReactElement | undefined;
  changedOrNewTask: TaskI = {};

  // Блок достижений
  isAddAchievement = false;
  isEditAchievement = false;
  idEditableAchievement = 0;
  achievementsList: AchievementI[] = [];
  achievement_name = '';
  achievement_description = '';

  constructor() {
    makeAutoObservable(this);
  }

  //перезапись переменных

  setIsAddCourse(value: boolean) {
    this.isAddCourse = value;
  }

  setIsEditCourse(value: boolean) {
    this.isEditCourse = value;
  }

  setNameCourse(name: string) {
    this.name_course = name;
  }

  setDetailedDescription(description: string) {
    this.detailed_description = description;
  }

  setNewLessons(lessons: LessonI[]) {
    this.newLessons = lessons;
  }

  setNewLessonTitle(event: ChangeEvent<HTMLInputElement>) {
    this.newLessonTitle = String(event.target.value);
  }

  setIsAddLesson(value: boolean) {
    this.isAddLesson = value;
  }

  setEditableLessonTitle(event: ChangeEvent<HTMLInputElement>) {
    this.editableLessonTitle = String(event.target.value);
  }

  setAllUsersList(users: AllUsersListI[]) {
    this.allUsersList = users;
  }

  setBannedUsersList(users: AllUsersListI[]) {
    this.bannedUsersList = users;
  }

  setSortedUsersList(users: AllUsersListI[]) {
    this.sortedUsersList = users;
  }

  setIsSearch(value: boolean) {
    this.isSearch = value;
  }

  setEditOrAddTaskForm(data: EditOrAddTaskFormI) {
    this.isAddOrEditTask = true;
    this.editOrAddTaskForm = <AddNewTaskForm {...data} />;
  }

  setAchievementsList(value: AchievementI[]) {
    this.achievementsList = value;
  }

  setIsAddAchievement(value: boolean) {
    this.isAddAchievement = value;
  }

  setIsEditAchievement(value: boolean) {
    this.isEditAchievement = value;
  }

  addCourse() {
    this.isAddCourse = true;
    this.isEditCourse = false;
    this.newLessons = [];
    this.name_course = '';
    this.detailed_description = '';
  }

  editCourse(data: EditCourseI) {
    data.navigate('/administration');
    this.getEditableCourse(data.id);
    this.idEditableCourse = data.id;
    this.isEditCourse = true;
  }

  getEditableCourse(courseId: number) {
    axios
      .get(`${BASE_URL}/api/v1/course/big/${courseId}`, {
        headers: {
          Authorization: `Bearer ${userStore.token}`,
        },
      })
      .then(course => {
        this.setNameCourse(course.data.name_course);
        this.setDetailedDescription(course.data.detailed_description);
        this.setNewLessons(course.data.lesson);
      });
  }

  publicNewCourse(data: PublicNewCourseI) {
    data.event.preventDefault();
    let courseData: FormData | undefined;
    if (data.editOrAddCourseForm.current) {
      courseData = new FormData(data.editOrAddCourseForm.current);
      courseData.append('lesson', JSON.stringify(this.newLessons));
      if (this.isEditCourse) {
        const preview_image = courseData.get('preview_image') as File;
        if (preview_image.name === '') {
          courseData.delete('preview_image');
        }
        axios
          .patch(
            `${BASE_URL}/api/v1/admin/course/update/${this.idEditableCourse}`,
            courseData,
            {
              headers: {
                Authorization: `Bearer ${userStore.token}`,
              },
            },
          )
          .then(() => {
            this.setIsEditCourse(false);
            coursesStore.getCourses();
          })
          .catch(error => {
            console.error(`Ошибка при получении данных: ${error}`);
          });
      } else if (this.isAddCourse) {
        axios
          .post(`${BASE_URL}/api/v1/admin/course/form`, courseData, {
            headers: {
              Authorization: `Bearer ${userStore.token}`,
            },
          })
          .then(() => {
            this.setIsAddCourse(false);
            coursesStore.getCourses();
          })
          .catch(error => {
            console.error(`Ошибка при получении данных: ${error}`);
          });
      }
    }
  }

  closeCourseForm = (event: React.MouseEvent) => {
    event.preventDefault();
    this.isAddCourse = false;
    this.isEditCourse = false;
  };

  createLesson(event: React.MouseEvent) {
    event.preventDefault();
    if (this.newLessonTitle !== '') {
      this.newLessons = [
        ...this.newLessons,
        {
          id: 0,
          name_lesson: this.newLessonTitle,
          task_string: [],
        },
      ];
      this.newLessonTitle = '';
      this.isAddLesson = false;
    }
  }

  deleteLesson(lessonIndex: number) {
    this.newLessons = [
      ...this.newLessons.slice(0, lessonIndex),
      ...this.newLessons.slice(lessonIndex + 1),
    ];
  }

  startEditLesson(data: StartEditLessonI) {
    data.event.preventDefault();
    data.setIsEdit(true);
    this.editableLessonTitle = data.name_lesson;
  }

  changeLessonTitle(data: ChangeLessonTitleI) {
    data.event.preventDefault();
    this.newLessons = [
      ...this.newLessons.slice(0, data.lessonIndex),
      {
        ...this.newLessons[data.lessonIndex],
        name_lesson: this.editableLessonTitle,
      },
      ...this.newLessons.slice(data.lessonIndex + 1),
    ];
    data.setIsEdit(false);
  }

  // Блок строк с уроками
  printNewTaskStrings(lessonIndex: number) {
    return adminStore.newLessons[lessonIndex].task_string.map(
      (taskStr, taskStrIndex) => {
        return (
          <NewTaskString
            key={taskStrIndex}
            lessonIndex={lessonIndex}
            thisTaskStringIndex={taskStrIndex}
          />
        );
      },
    );
  }

  createTaskString(index: number) {
    this.newLessons[index] = {
      ...this.newLessons[index],
      task_string: [
        ...this.newLessons[index].task_string,
        {
          id: 0,
          task: [],
        },
      ],
    };
  }

  deleteTaskString(data: DeleteTaskStringI) {
    this.newLessons[data.lessonIndex].task_string = [
      ...this.newLessons[data.lessonIndex].task_string.slice(
        0,
        data.taskStringIndex,
      ),
      ...this.newLessons[data.lessonIndex].task_string.slice(
        data.taskStringIndex + 1,
      ),
    ];
  }

  printNewTasks(data: PrintNewTasksI) {
    return this.newLessons[data.lessonIndex].task_string[
      data.taskStringIndex
    ].task.map((thisTask: TaskI, thisTaskIndex: number) => {
      return (
        <NewTask
          key={thisTaskIndex}
          lessonIndex={data.lessonIndex}
          taskStringIndex={data.taskStringIndex}
          thisTask={thisTask}
          thisTaskIndex={thisTaskIndex}
        />
      );
    });
  }

  setChangedOrNewTask(taskData: FormData) {
    taskData.forEach((value, key) => {
      const keyName = key as keyof TaskI;
      if (typeof value === 'string' || typeof value === 'number') {
        (this.changedOrNewTask[keyName] as string | number) = value;
      }
    });
  }

  startAddNewTask(data: StartAddNewTaskI) {
    data.event.preventDefault();
    this.setEditOrAddTaskForm({
      lessonIndex: data.lessonIndex,
      taskStringIndex: data.taskStringIndex,
      taskIndex: null,
      editableTask: null,
    });
    this.isAddOrEditTask = true;
  }

  createTask(data: CreateTaskI) {
    data.taskData && this.setChangedOrNewTask(data.taskData);
    this.newLessons[data.lessonIndex].task_string[data.taskStringIndex].task = [
      ...this.newLessons[data.lessonIndex].task_string[data.taskStringIndex]
        .task,
      { ...this.changedOrNewTask },
    ];
  }

  changeTask(data: ChangeTaskI) {
    data.taskData && this.setChangedOrNewTask(data.taskData);
    this.newLessons[data.lessonIndex].task_string[data.taskStringIndex].task = [
      ...this.newLessons[data.lessonIndex].task_string[
        data.taskStringIndex
      ].task.slice(0, data.taskIndex),
      { ...this.changedOrNewTask },
      ...this.newLessons[data.lessonIndex].task_string[
        data.taskStringIndex
      ].task.slice(data.taskIndex + 1),
    ];
  }

  deleteTask(data: DeleteTaskI) {
    this.newLessons[data.lessonIndex].task_string[data.taskStringIndex].task = [
      ...this.newLessons[data.lessonIndex].task_string[
        data.taskStringIndex
      ].task.slice(0, data.taskIndex),
      ...this.newLessons[data.lessonIndex].task_string[
        data.taskStringIndex
      ].task.slice(data.taskIndex + 1),
    ];
  }

  getNewTaskForm() {
    return this.editOrAddTaskForm;
  }

  createOrChangeTask(data: CreateOrChangeTaskI) {
    data.event.preventDefault();
    let taskData: FormData | undefined;
    if (data.editOrAddTaskForm.current) {
      taskData = new FormData(data.editOrAddTaskForm.current);
      data.editableTask
        ? taskData?.append('id', String(data.editableTask.id))
        : taskData?.append('id', '0');
    }
    if (data.taskIndex !== null && data.editableTask !== null) {
      this.changeTask({
        lessonIndex: data.lessonIndex,
        taskStringIndex: data.taskStringIndex,
        taskIndex: data.taskIndex,
        taskData,
      });
    } else {
      this.createTask({
        lessonIndex: data.lessonIndex,
        taskStringIndex: data.taskStringIndex,
        taskData,
      });
    }
    this.isAddOrEditTask = false;
  }

  closeNewTaskForm = (event: React.MouseEvent) => {
    event.preventDefault();
    this.isAddOrEditTask = false;
  };

  addAchievement() {
    this.isAddAchievement = true;
    this.isEditAchievement = false;
    this.achievement_name = '';
    this.achievement_description = '';
  }

  editAchievement(data: EditAchievementI) {
    this.isEditAchievement = true;
    this.isAddAchievement = false;
    this.idEditableAchievement = data.id;
    this.achievement_name = data.name;
    this.achievement_description = data.description;
  }

  getAchievements() {
    axios
      .get(`${BASE_URL}/api/v1/admin/entrance/achievement`, {
        headers: {
          Authorization: `Bearer ${userStore.token}`,
          'Content-Type': 'application/json;charset=utf-8',
        },
      })
      .then(response => {
        this.setAchievementsList(response.data);
      })
      .catch(error => {
        console.error(`Ошибка при получении данных: ${error}`);
      });
  }

  publicNewAchievement(data: PublicNewAchievementI) {
    data.event.preventDefault();
    let achievementData: FormData | undefined;
    if (data.editOrAddAchievementForm.current) {
      achievementData = new FormData(data.editOrAddAchievementForm.current);
      if (this.isAddAchievement) {
        axios
          .post(
            `${BASE_URL}/api/v1/admin/entrance/achievement`,
            achievementData,
            {
              headers: {
                Authorization: `Bearer ${userStore.token}`,
              },
            },
          )
          .then(() => {
            this.setIsAddAchievement(false);
            this.getAchievements();
          })
          .catch(error => {
            console.error(`Ошибка при получении данных: ${error}`);
          });
      } else if (this.isEditAchievement) {
        const achievement_photo = achievementData.get(
          'achievement_photo',
        ) as File;
        if (achievement_photo.name === '') {
          achievementData.delete('achievement_photo');
        }
        axios
          .patch(
            `${BASE_URL}/api/v1/admin/entrance/achievement/${this.idEditableAchievement}`,
            achievementData,
            {
              headers: {
                Authorization: `Bearer ${userStore.token}`,
              },
            },
          )
          .then(() => {
            this.setIsEditAchievement(false);
            this.getAchievements();
          })
          .catch(error => {
            console.error(`Ошибка при получении данных: ${error}`);
          });
      }
    }
  }

  deleteAchievement(data: DeleteAchievementI) {
    axios
      .delete(`${BASE_URL}/api/v1/admin/entrance/achievement/${data.id}`, {
        headers: {
          Authorization: `Bearer ${userStore.token}`,
          'Content-Type': 'application/json;charset=utf-8',
        },
      })
      .then(() => {
        if (data.index) {
          adminStore.setAchievementsList([
            ...adminStore.achievementsList.slice(0, data.index),
            ...adminStore.achievementsList.slice(data.index + 1),
          ]);
        }
      })
      .catch(error => {
        console.error(`Ошибка при получении данных: ${error}`);
      });
  }

  closeAchievementForm = (event: React.MouseEvent) => {
    event.preventDefault();
    this.isAddAchievement = false;
    this.isEditAchievement = false;
  };

  printUsers(array: AllUsersListI[]) {
    return array.map((data: AllUsersListI, index: number) => {
      return <UserBlock key={data.id} {...data} index={index} />;
    });
  }

  getAllUsers() {
    axios
      .get(`${BASE_URL}/api/v1/admin/entrance/all_users`, {
        headers: {
          Authorization: `Bearer ${userStore.token}`,
        },
      })
      .then(response => {
        const bannedList: AllUsersListI[] = [];
        const allList: AllUsersListI[] = [];
        response.data.forEach((user: AllUsersListI) => {
          user.is_banned ? bannedList.push(user) : allList.push(user);
        });
        this.setAllUsersList(allList);
        this.setBannedUsersList(bannedList);
      })
      .catch(error => {
        console.error(`Ошибка при получении данных: ${error}`);
      });
  }

  banUser(data: BanUnbanUserI) {
    axios
      .post(
        `${BASE_URL}/api/v1/admin/entrance/permanent_ban/${data.id}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${userStore.token}`,
          },
        },
      )
      .then(() => {
        this.allUsersList = [
          ...this.allUsersList.slice(0, data.index),
          ...this.allUsersList.slice(data?.index + 1),
        ];
        this.bannedUsersList = [
          ...this.bannedUsersList,
          { ...data?.user, is_banned: true },
        ];
      })
      .catch(error => {
        console.error(`Ошибка при получении данных: ${error}`);
      });
  }

  handleBanUser(id: number) {
    axios
      .post(
        `${BASE_URL}/api/v1/admin/entrance/permanent_ban/${id}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${userStore.token}`,
          },
        },
      )
      .catch(error => {
        console.error(`Ошибка при получении данных: ${error}`);
      });
  }

  unbanUser(data: BanUnbanUserI) {
    axios
      .post(
        `${BASE_URL}/api/v1/admin/entrance/unbanned/${data.id}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${userStore.token}`,
          },
        },
      )
      .then(() => {
        this.bannedUsersList = [
          ...adminStore.bannedUsersList.slice(0, data.index),
          ...adminStore.bannedUsersList.slice(data.index + 1),
        ];
        this.allUsersList = [
          ...this.allUsersList,
          { ...data.user, is_banned: false },
        ];
      })
      .catch(error => {
        console.error(`Ошибка при получении данных: ${error}`);
      });
  }

  search(data: SearchI) {
    if (data.searchText !== '') {
      this.setIsSearch(true);
      const usersList: AllUsersListI[] = [];
      if (data.allOrBannedUsers) {
        adminStore.allUsersList.map(user => {
          if (
            user.first_name
              .toLocaleLowerCase()
              .includes(data.searchText.toLocaleLowerCase())
          ) {
            usersList.push(user);
          }
        });
      } else {
        adminStore.bannedUsersList.map(user => {
          if (user.first_name.includes(data.searchText)) {
            usersList.push(user);
          }
        });
      }
      this.setSortedUsersList(usersList);
    }
  }
}

export const adminStore = new AdminStore();
