import { observer } from 'mobx-react-lite';
import { PLATFORM_CART_NAVIGATE } from 'components/Header/components/Modals/constants';
import ModalPlatformCart from 'components/Header/components/Modals/ModalPlatform/components/ModalPlatformCart/ModalPlatformCart';
import {
  type CartNavigateI,
  type ModalPlatformCartI,
} from 'Interfaces/helpInterfaces';
import { userStore } from 'Stores/UserStore';
import cn from 'classnames';
import {
  NAVIGATE_HEADER_ADMIN,
  NAVIGATE_HEADER_USER,
} from 'components/Header/constant';
import CartNavigate from 'components/Header/components/NavigateHeader/components/CartNavigate/CartNavigate';
import styles from './ModalPlatform.module.scss';

const ModalPlatform = observer(() => {
  const { modalPlatformVis } = userStore;
  return (
    <>
      <div
        className={cn({
          [styles.close__modal__btn]: modalPlatformVis,
          [styles.close__modal__btn__dis]: !modalPlatformVis,
        })}
        onClick={() => userStore.setModalPlatformVis(false)}
      />
      <div
        className={cn(styles.modal__navigate, {
          [styles.close]: !modalPlatformVis,
        })}
      >
        {modalPlatformVis &&
          PLATFORM_CART_NAVIGATE.map((cart: ModalPlatformCartI, index) => {
            return <ModalPlatformCart {...cart} key={index} />;
          })}

        <div
          className={cn(styles.routes, {
            [styles.close_routes]: !modalPlatformVis,
          })}
        >
          {userStore.user_header.is_staff
            ? NAVIGATE_HEADER_ADMIN.map(
              (cartNavigate: CartNavigateI, index) => (
                <CartNavigate {...cartNavigate} key={index} />
              ),
            )
            : NAVIGATE_HEADER_USER.map((cartNavigate: CartNavigateI, index) => (
              <CartNavigate {...cartNavigate} key={index} />
            ))}
        </div>
      </div>
    </>
  );
});

export default ModalPlatform;
