import { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Loading } from 'components/index';
import { userStore } from 'Stores/index';
import PreviewSection from '../components/PreviewSection/PreviewSection';
import styles from './Register.module.scss';
import RegSidebar from './components/RegSidebar/RegSidebar';

const Register = observer(() => {
  const { loading } = userStore;

  useEffect(() => {
    document.title = 'Регистрация';
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <section className={styles.register}>
          <PreviewSection />
          <RegSidebar />
        </section>
      )}
    </>
  );
});

export default Register;
