import { observer } from 'mobx-react-lite';
import { type UserChatI } from 'Interfaces/chatinterface';
import { websocketStore } from 'Stores/WebsocketStore';
import { chatStore } from 'Stores/ChatStore';
import ContextMenuUser from 'pages/Chat/components/ContextMenuUser/ContextMenuUser';
import { WEBSOCKET_MSG } from '../../../../Const';
import userLogo from '../../../../style/img/UserIcon.svg';
import styles from './UserCart.module.scss';

const UserCart = observer((data: UserChatI) => {
  return (
    <div
      className={
        !chatStore.castomContextUserMenuVis
          ? `${styles.user__cart}`
          : `${styles.user__cart} ${styles.dont__touch}`
      }
      onClick={() => {
        !chatStore.castomContextUserMenuVis &&
          websocketStore.sendMessage(WEBSOCKET_MSG.CHAT, data.interlocutor?.id);
      }}
      onContextMenu={event => {
        event.preventDefault();
        chatStore.setModalSettingsChat(!chatStore.modalSettingsChat);
        chatStore.setYIndexClickUser(event.pageY + 7 + 'px');
        chatStore.setXIndexClickUser(event.pageX + 7 + 'px');
        chatStore.setCastomContextMenuUserVis(
          !chatStore.castomContextUserMenuVis,
        );
        chatStore.setCastomContextMenuVis(-1);
        chatStore.setUserCartId(data.id);
      }}
    >
      <div className={styles.time__wrapper}>
        <img src={userLogo} alt="" />
        <div className={styles.inf__block}>
          <p>
            {data.interlocutor?.first_name} {data.interlocutor?.last_name}
          </p>
          <p>{data.last_message}</p>
        </div>
      </div>
      <p>11:11</p>
      {chatStore.castomContextUserMenuVis && <ContextMenuUser />}
    </div>
  );
});

export default UserCart;
