import { observer } from 'mobx-react-lite';
import { type DocumentationI } from 'Interfaces/coursesInterfaces';
import styles from './MustCart.module.scss';

const MustCurt = observer((data: DocumentationI) => {
  return (
    <div className={styles.must__cart}>
      <div className={styles.circle__number}>
        <p>{data.id}</p>
      </div>
      <h4>{data.name}</h4>
      <p>{data.description}</p>
    </div>
  );
});

export default MustCurt;
