import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import {
  Page1,
  Page10,
  Page11,
  Page12,
  Page2,
  Page3,
  Page4,
  Page5,
  Page6,
  Page7,
  Page8,
  Page9,
} from 'pages/Faq/components/mokData';
import { coursesStore } from 'Stores/CourseStore';
import styles from './Faq.module.scss';

const FAQ = observer(() => {
  const [currentPage, setCurrentPage] = useState<number>(1);

  useEffect(() => {
    document.title = 'FaQ';
    coursesStore.courseTask.flag = '';
  }, []);

  const nextPage = () => {
    setCurrentPage(prevPage => (prevPage < 12 ? prevPage + 1 : 1));
    window.scrollTo(0, 0);
  };

  const prevPage = () => {
    setCurrentPage(prevPage => (prevPage > 1 ? prevPage - 1 : 12));
    window.scrollTo(0, 0);
  };

  return (
    <>
      <div className={styles.faq_container}>
        <div className={styles.faq_text}>
          {currentPage == 1 && <Page1 />}
          {currentPage == 2 && <Page2 />}
          {currentPage == 3 && <Page3 />}
          {currentPage == 4 && <Page4 />}
          {currentPage == 5 && <Page5 />}
          {currentPage == 6 && <Page6 />}
          {currentPage == 7 && <Page7 />}
          {currentPage == 8 && <Page8 />}
          {currentPage == 9 && <Page9 />}
          {currentPage == 10 && <Page10 />}
          {currentPage == 11 && <Page11 />}
          {currentPage == 12 && <Page12 />}
        </div>
        <div className={styles.faq_current_page}>{currentPage}</div>
      </div>

      <div className={styles.faq_btn_container}>
        <button className={styles.faq_button} onClick={prevPage}>
          Назад
        </button>
        <button className={styles.faq_button} onClick={nextPage}>
          Далее
        </button>
      </div>
    </>
  );
});

export default FAQ;
