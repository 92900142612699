import { observer } from 'mobx-react-lite';
import styles from './InfoBlock.module.scss';

interface Props {
  title?: string;
  info?: string;
}

export const InfoBlock = observer((data: Props) => {
  return (
    <div className={styles.info}>
      <div className={styles.block}>
        <p>{data.title}</p>
        <p>{data.info}</p>
      </div>
      <div className={styles.line} />
    </div>
  );
});
