import userLogo from 'style/img/UserIcon.svg';
import { type TopUserI } from 'Interfaces/userInterfaces';
import { useNavigate } from 'react-router-dom';
import { BASE_URL } from 'consturl';
import { Button } from 'components/index';
import styles from './RatingUser.module.scss';

export default function RatingUser({ userdata }: { userdata: TopUserI }) {
  const navigate = useNavigate();
  return (
    <div className={styles.rating__list__user}>
      <p className={styles.rating__user__place}>{userdata.place}</p>

      <div className={styles.user__info_text}>
        <img
          className={styles.rating__user__image}
          src={
            userdata.avatar_photo
              ? `${BASE_URL}${userdata.avatar_photo}`
              : userLogo
          }
          alt="user-icon"
        />
        <p className={styles.rating__user__info__name}>
          {userdata.last_name} {userdata.first_name}
        </p>
      </div>

      <p className={styles.rating__user__info__rank}>{userdata.rank}</p>
      <p className={styles.rating__user__info__exp}>{userdata.exp}</p>
      <div className={styles.btn_block}>
        <Button
          text="Перейти"
          className={styles.btn}
          onClick={() => {
            navigate(`/profile/${userdata.id}`);
          }}
        />
      </div>
    </div>
  );
}
