import { createCoursesStore } from 'Stores/CreateCourseStore';
import { CreateCourseBtn } from 'pages/CreateCourse/components/btns/createCouseBtn';
import { observer } from 'mobx-react-lite';
import styles from './StringPath.module.scss';

export const StringPath = observer(() => {
  const { createdCourse, selectString, selectLesson } = createCoursesStore;

  return (
    <div className={styles.lessons}>
      <h1 className={styles.name}>Строки</h1>
      <div className={styles.cyrcle_counter}>
        <CreateCourseBtn
          type="add"
          onClick={() => createCoursesStore.addString()}
        />
        <CreateCourseBtn
          type="delete"
          onClick={() => createCoursesStore.deleteString()}
        />

        {createdCourse.lesson[selectLesson]?.task_string?.map((_, index) => (
          <CreateCourseBtn
            key={`task-string-${index}`}
            type="normal"
            onClick={() => createCoursesStore.setSelectString(index)}
            number={index + 1}
            selected={selectString === index}
          />
        ))}
      </div>
      <div className={styles.cyrcle_counter} />
    </div>
  );
});
