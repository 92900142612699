import { type EntranceInputI } from 'Interfaces/userInterfaces';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { entranceStore } from 'Stores/EntranceStore';
import closeEye from '../../Register/img/icons/close-password-icon.svg';
import openEye from '../../Register/img/icons/open-password-icon.svg';
import openErrorEye from '../../Register/img/icons/error-open-password-icon.svg';
import closeErrorEye from '../../Register/img/icons/error-close-password-icon.svg';
import styles from './EntranceInput.module.scss';

const EntranceInput = observer((input: EntranceInputI) => {
  const [state, setState] = useState<boolean>(false);
  return (
    <div className={styles.wrapp}>
      <label htmlFor="">{input.label}</label>
      <div
        className={
          entranceStore.loginError ? styles.reg__input_error : styles.reg__input
        }
      >
        <img src={input.logo} alt="123" />

        <input
          onChange={e => input.onChange(e.target.value)}
          placeholder={input.placeholder}
          type={input.password ? (state ? 'text' : 'password') : 'text'}
          className={styles.input}
          onFocus={() => entranceStore.setLoginError(false)}
        />
        {input.icon && (
          <img
            src={
              state
                ? entranceStore.loginError
                  ? openErrorEye
                  : openEye
                : entranceStore.loginError
                  ? closeErrorEye
                  : closeEye
            }
            className={styles.icon}
            onClick={() => setState(!state)}
            alt={'eye'}
          />
        )}
      </div>
    </div>
  );
});

export default EntranceInput;
