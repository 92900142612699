import { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { type ButtonSetImageI } from 'Interfaces/index';
import { userStore } from 'Stores/index';
import achievementI from 'style/img/achivmentDefault.svg';
import darkCameraIcon from './img/camera-icon-dark.svg';
import lightCameraIcon from './img/camera-icon-light.svg';
import styles from './ButtonSetImage.module.scss';

export const ButtonSetImage = observer((data: ButtonSetImageI) => {
  const [imageName, setImageName] = useState<string | null>(null);
  const { title, buttonName, inputName, labelClass, titleClass } = data;
  return (
    <label className={labelClass && styles[labelClass]}>
      {title && <p className={titleClass && styles[titleClass]}>{title}</p>}
      <input
        onChange={event => {
          setImageName(event.target.files ? event.target.files[0].name : null);
        }}
        type="file"
        className={styles.upload__image__input}
        name={inputName}
      />
      <div className={styles.upload__image__button__wrapper}>
        <div className={styles.upload__image__button}>
          <img
            className={styles.upload__image__icon}
            src={
              userStore.userSiteTheme === 'dark'
                ? darkCameraIcon
                : lightCameraIcon
            }
            alt="camera-icon"
          />
          {buttonName}
        </div>
        <span className={styles.image__name}>{imageName}</span>
      </div>
    </label>
  );
});
