import { observer } from 'mobx-react-lite';
import { type CourseCuttedI } from 'Interfaces/index';
import { BASE_URL } from 'consturl';
import { useNavigate } from 'react-router-dom';
import { userStore } from 'Stores/UserStore';
import { coursesStore } from 'Stores/CourseStore';
import userDef from '../../pages/Home/img/CardMedia.png';
import styles from './Course.module.scss';
import settings from './img/Settings.svg';

const Course = observer(({ preview_image, name_course, id }: CourseCuttedI) => {
  const navigate = useNavigate();

  const printTable = (id: number) => {
    localStorage.setItem('course', String(id));
    coursesStore.setIsOpenTableCourse(!coursesStore.isOpenTableCourse);
  };

  return (
    <li className={styles.course} onClick={() => navigate('/course/' + id)}>
      <img
        className={styles.course__image}
        src={preview_image ? BASE_URL + preview_image : userDef}
        alt=""
      />

      <div
        className={
          userStore.user_header.is_staff
            ? styles.course__info_admin
            : styles.course__info
        }
      >
        <h3>{name_course}</h3>
        {userStore.user_header.is_staff &&
        !window.location.href.includes('create_course') ? (
            <div onClick={e => e.stopPropagation()}>
              <p onClick={() => printTable(id)} className={styles.statistic}>
              СТАТИСТИКА
              </p>
            </div>
          ) : (
            userStore.user_header.is_staff &&
          window.location.href.includes('create_course') && (
              <div className={styles.edit}>
                <img src={settings} />
                <p className={styles.edit_text}>Редактировать курс</p>
              </div>
            )
          )}
      </div>
    </li>
  );
});

export default Course;
