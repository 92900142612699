import { type CompletedCourseI } from 'Interfaces/coursesInterfaces';
import { BASE_URL } from 'consturl';
import styles from './сompletedCourse.module.scss';

function CompletedCourse(data: CompletedCourseI) {
  return (
    <li className={styles.started__course}>
      <div className={styles.started__course__info}>
        <div className={styles.title__block}>
          <img
            src={`${BASE_URL}${data.preview_image}`}
            className={styles.pic}
            alt="course-icon"
          />
          <h2>{data.course_name}</h2>
        </div>

        <p>
          {data.completed_task_count} / {data.task_count}
        </p>
        <p>{data.completion_percentage}</p>

        <div className={styles.started__course__progress__info}>
          <div className={styles.started__course__progress}>
            <div
              className={styles.started__course__progress__line}
              style={{ width: `${data.completion_percentage}` }}
            />
            <div className={styles.count_block}>
              <p className={styles.count}>0</p>
              <p className={styles.count}>100</p>
            </div>
          </div>
        </div>
      </div>
    </li>
  );
}

export default CompletedCourse;
