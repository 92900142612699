import { observer } from 'mobx-react-lite';
import { HOME_TXT } from 'pages/Home/constant';
import styles from './previewSection.module.scss';

const PreviewSection = observer(() => {
  const { title, description } = HOME_TXT;
  return (
    <section className={styles.preview__section}>
      <div className="container">
        <div className={styles.cart__preview}>
          <h1 className={styles.title}>{title}</h1>
          <p>{description}</p>
        </div>
      </div>
    </section>
  );
});

export default PreviewSection;
