import { observer } from 'mobx-react-lite';
import { userStore } from 'Stores/UserStore';
import bell from '../../img/Notification.svg';
import userDef from '../../img/Avatars.svg';
import vector from '../../img/Vector.svg';
import ModalUser from '../Modals/ModalUser/ModalUser';
import styles from './UserHeader.module.scss';

const UserHeader = observer(() => {
  const { modalUserVis } = userStore;
  return (
    <>
      <div className={styles.user__header}>
        <img
          src={bell}
          alt=""
          className={styles.notifications}
          onClick={() =>
            userStore.setIsOpenNotifications(!userStore.isOpenNotifications)
          }
        />
        <div
          className={styles.modal__user}
          onClick={() => userStore.setModalUserVis(!modalUserVis)}
        >
          <img src={userDef} alt="" />
          <img src={vector} alt="" />
        </div>
      </div>
      {modalUserVis && <ModalUser />}
    </>
  );
});
export default UserHeader;
