import { observer } from 'mobx-react-lite';
import { type DocumentationI } from 'Interfaces/coursesInterfaces';
import styles from './CartNote.module.scss';

const CartNote = observer((data: DocumentationI) => {
  return (
    <div className={styles.cart__note}>
      <h4>{data.name}</h4>
      <p>{data.description}</p>
    </div>
  );
});

export default CartNote;
