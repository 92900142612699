import { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { userStore } from 'Stores/UserStore';
import { adminStore } from 'Stores/AdminStore';
import { type NewLessonI } from 'Interfaces/adminInterfaces';
import lesson_arrow_icon_light from '../../img/lesson-arrow-icon-light.svg';
import lesson_arrow_icon_dark from '../../img/lesson-arrow-icon-dark.svg';
import Input from '../../../../components/Input/Input';
import styles from './NewLesson.module.scss';

const NewLesson = observer((data: NewLessonI) => {
  const { newLessonIndex, thisNewLesson } = data;
  const [isOpenLesson, setIsOpenLesson] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  return (
    <div className={styles.new__lesson__block}>
      <div
        className={
          isOpenLesson
            ? `${styles.new__lesson} ${styles.add__lesson__open__arrow} `
            : styles.new__lesson
        }
      >
        {isEdit ? (
          <Input
            inputType="text"
            inputPlaceholder="Новое название урока"
            inputClass="new__lesson__title__input"
            inputOnChange={event => adminStore.setEditableLessonTitle(event)}
            onBlur={event =>
              adminStore.changeLessonTitle({
                event,
                lessonIndex: newLessonIndex,
                setIsEdit,
              })
            }
            inputDefaultValue={thisNewLesson.name_lesson}
          />
        ) : (
          <p>{thisNewLesson.name_lesson}</p>
        )}
        <div className={styles.lesson__tools__wrapper}>
          <button
            className={`${styles.lesson__edit__button} ${styles.lesson__tool__button}`}
            onClick={event =>
              adminStore.startEditLesson({
                event,
                name_lesson: thisNewLesson.name_lesson,
                setIsEdit,
              })
            }
          ></button>
          {adminStore.isEditCourse === false && (
            <button
              className={`${styles.lesson__delete__button} ${styles.lesson__tool__button}`}
              onClick={event => {
                event.preventDefault();
                adminStore.deleteLesson(newLessonIndex);
              }}
            ></button>
          )}
          <img
            className={styles.lesson__arrow}
            onClick={() => setIsOpenLesson(!isOpenLesson)}
            src={
              userStore.userSiteTheme === 'dark'
                ? lesson_arrow_icon_dark
                : lesson_arrow_icon_light
            }
            alt="arrow-icon"
          />
        </div>
      </div>
      {isOpenLesson && adminStore.printNewTaskStrings(newLessonIndex)}
      {adminStore.isAddOrEditTask === false &&
        adminStore.isEditCourse === false && (
        <div
          onClick={() => adminStore.createTaskString(newLessonIndex)}
          className={
            isOpenLesson
              ? `${styles.new__lesson__add__taskstring}`
              : `${styles.new__lesson__add__taskstring} ${styles.new__lesson__close}`
          }
        />
      )}
    </div>
  );
});

export default NewLesson;
