import { createCoursesStore } from 'Stores/CreateCourseStore';
import styles from './practiceTexteteaBlock.module.scss';

export const PracticeTexteteaBlock = () => {
  const { createdCourse, selectString, selectLesson, selectTask } =
    createCoursesStore;

  const task =
    createdCourse.lesson[selectLesson]?.task_string[selectString]?.task[
      selectTask
    ];
  return (
    <>
      <textarea
        className={styles.textarea}
        value={task?.auto_test || ''}
        onChange={event => createCoursesStore.changeTaskAutoTest(event)}
        placeholder={'auto_test'}
      />

      <textarea
        className={styles.textarea}
        value={task?.name_func || ''}
        onChange={event => createCoursesStore.changeTaskNameFunc(event)}
        placeholder={'name_func'}
      />
    </>
  );
};
