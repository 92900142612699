import { observer } from 'mobx-react-lite';
import { userStore } from 'Stores/UserStore';
import Button from 'components/Buttons/Button/Button';
import { useNavigate } from 'react-router-dom';
import defaultUserLogo from '../../../img/Avatars.svg';
import btnExit from '../../../img/btn_exit.svg';
import vector from '../../../img/Vector.svg';
import styles from './ModalUser.module.scss';

const ModalUser = observer(() => {
  const { user_header, modalUserVis } = userStore;
  const navigate = useNavigate();

  const navigateHandle = () => {
    userStore.setModalUserVis(false);
    navigate('/profile');
    window.location.reload();
  };

  const logout = () => {
    window.location.reload();
    userStore.logOut();
  };

  return (
    <>
      <div
        className={styles.modal__user__bg}
        onClick={() => userStore.setModalUserVis(false)}
      />
      <div className={styles.modal__user}>
        {modalUserVis && (
          <div className={styles.modal}>
            <div className={styles.modal__user__title} onClick={navigateHandle}>
              <img
                src={defaultUserLogo}
                alt=""
                className={styles.title__logo}
              />
              <p>
                {user_header.first_name} {user_header.last_name}
              </p>
              <img src={vector} alt="" className={styles.title__vector} />
            </div>
            <div className={styles.modal__user__btn} onClick={logout}>
              <img src={btnExit} alt="Exit" />
              <Button text="Выйти" />
            </div>
          </div>
        )}
      </div>
    </>
  );
});

export default ModalUser;
