import { observer } from 'mobx-react-lite';
import ChatImg from 'style/img/Chat.png';
import styles from './SelectChat.module.scss';

const SelectChat = observer(() => {
  return (
    <div className={styles.select__chat}>
      <img src={ChatImg} alt="" />
      <p>Выбирите чат</p>
    </div>
  );
});

export default SelectChat;
