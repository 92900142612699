import { observer } from 'mobx-react-lite';
import { type LessonCuttedI } from 'Interfaces/coursesInterfaces';
import { coursesStore } from 'Stores/CourseStore';
import Lesson from '../Lesson/Lesson';
import styles from './lessonSection.module.scss';

const LessonSection = observer(() => {
  return (
    <section className={`${styles.course__lessons__section} container`}>
      <div className={styles.lists}>
        {coursesStore.course.lesson.map((lesson: LessonCuttedI) => {
          return <Lesson key={lesson.id} {...lesson} />;
        })}
      </div>
    </section>
  );
});

export default LessonSection;
