import { observer } from 'mobx-react-lite';
import ModalPlatform from 'components/Header/components/Modals/ModalPlatform/ModalPlatform';
import { userStore } from 'Stores/UserStore';
import { useNavigate } from 'react-router-dom';
import selectPlatform from '../../img/Select-platform.svg';
import logo from '../../img/Logo-alabuga-long.svg';
import styles from './TitleHeader.module.scss';

const TitleHeader = observer(() => {
  const navigate = useNavigate();

  return (
    <div className={styles.title__header}>
      <div
        className={styles.menu__platform}
        onClick={() => userStore.setModalPlatformVis(true)}
      >
        <img src={selectPlatform} alt="" />
      </div>
      <img
        src={logo}
        alt=""
        className={styles.logo}
        onClick={() => navigate('/')}
      />
      <ModalPlatform />
    </div>
  );
});
export default TitleHeader;
