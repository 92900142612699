import { observer } from 'mobx-react-lite';
import { type ChatMessageI } from 'Interfaces/chatinterface';
import { chatStore } from 'Stores/ChatStore';
import ContextMenu from 'pages/Chat/components/ContextMenu/ContextMenu';
import styles from './Message.module.scss';

const Message = observer((data: ChatMessageI) => {
  return (
    <div
      className={
        data.sender?.id === chatStore.chat.interlocutor?.id
          ? `${styles.message__block} ${styles.another__message__block}`
          : `${styles.message__block} ${styles.my__message__block}`
      }
      id="message"
    >
      {chatStore.castomContextMenuVis == data.id ? (
        <div
          className={styles.scroll__visible__block}
          onContextMenu={() => chatStore.setCastomContextMenuVis(0)}
          onClick={() => chatStore.setCastomContextMenuVis(-1)}
        ></div>
      ) : (
        ''
      )}

      <div
        className={
          data.sender?.id === chatStore.chat.interlocutor?.id
            ? `${styles.message} ${styles.another__message}`
            : `${styles.message} ${styles.my__message}`
        }
        onContextMenu={event => {
          event.preventDefault();
          chatStore.setYIndexClickMessage(event.pageY + 'px');
          chatStore.setMessageId(data.id);
          chatStore.setMessageSenderId(data.sender?.id);
          chatStore.setCastomContextMenuVis(data.id);
          chatStore.setCastomContextMenuUserVis(false);
          chatStore.setSearchIndexUpdateMessage();
          if (event.pageX > 800) {
            chatStore.setXIndexClickMessage(event.pageX - 130 + 'px');
          } else {
            chatStore.setXIndexClickMessage(event.pageX + 'px');
          }
        }}
      >
        <p>{data.text}</p>
        {chatStore.castomContextMenuVis > 1 ? <ContextMenu /> : ''}
      </div>
    </div>
  );
});

export default Message;
