import { type FormEvent, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';
import { type TaskCuttedI } from 'Interfaces/coursesInterfaces';
import { coursesStore, userStore } from 'Stores/index';
import cn from 'classnames';
import cnBind from 'classnames/bind';
import doneStatus from '../../../../img/completed-course.svg';
import inProgress from '../../../../img/unlock.svg';
import blocked from '../../../../img/block.svg';
import styles from './task.module.scss';

const cx = cnBind.bind(styles);

const Task = observer((data: TaskCuttedI) => {
  const navigate = useNavigate();

  const taskCN = cn(
    styles.task,
    cx('', {
      blocked: data.flag === 'false',
      complete: data.flag === 'true',
      progress: data.flag === 'in progress',
    }),
  );

  const resultsCN = cn(
    styles.task__result,
    cx('', {
      hard: data.flag === 'false',
      done: data.flag === 'true',
      medium: data.flag === 'in progress',
    }),
  );

  const complexityCN = cn(
    styles.task__complexity,
    cx('', {
      hard: data.flag === 'false',
      done: data.flag === 'true',
      medium: data.flag === 'in progress',
    }),
  );
  const nameTaskCN = cn(
    styles.task__title,
    cx('', {
      hard: data.flag === 'false',
      done: data.flag === 'true',
      medium: data.flag === 'in progress',
    }),
  );

  useEffect(() => {
    if (data.flag == 'in progress') {
      coursesStore.setLastCompletedTask(Number(data?.id));
    }
  }, []);

  const printTable = (id: number) => {
    localStorage.setItem('task', String(id));
    coursesStore.setIsOpenTable(!coursesStore.isOpenTable);
  };

  const handleRedirect = (event: FormEvent<HTMLDivElement>) => {
    event.stopPropagation();
    {
      if (
        (data.flag === 'in progress' && data.task_type === 'Theory') ||
        (data.flag === 'true' && data.task_type === 'Theory')
      ) {
        navigate(`testing/${data.id}`);
      } else if (
        (data.flag === 'in progress' && data.task_type !== 'Theory') ||
        (data.flag === 'true' && data.task_type !== 'Theory')
      ) {
        navigate(`task/${data.id}`);
      } else if (
        userStore.user_header.is_staff &&
        window.location.href.includes('user') &&
        data.task_type !== 'Theory'
      ) {
        navigate(`task/${data.id}`);
      } else if (
        userStore.user_header.is_staff &&
        window.location.href.includes('user') &&
        data.task_type === 'Theory'
      ) {
        navigate(`testing/${data.id}`);
      } else {
        navigate('');
      }
    }
  };

  return (
    <div className={taskCN} onClick={event => handleRedirect(event)}>
      <div className={styles.task__text_block}>
        <div className={styles.task__title_block}>
          <img
            className={styles.status}
            src={
              data.flag === 'true'
                ? doneStatus
                : data.flag === 'in progress'
                  ? inProgress
                  : blocked
            }
          />
          <h2 className={nameTaskCN}>{data.name_task}</h2>
        </div>
        <p className={complexityCN}>
          {data.complexity === 'Easy'
            ? 'Легкий'
            : data.complexity === 'Medium'
              ? 'Средний'
              : 'Сложный'}
        </p>
      </div>

      <div className={styles.block}>
        <p className={styles.task__exp}>
          <span
            className={
              data.flag === 'true'
                ? styles.done
                : data.flag === 'in progress'
                  ? styles.medium
                  : styles.hard
            }
          >
            {data.xp}XP
          </span>
        </p>

        <div onClick={e => e.stopPropagation()}>
          {userStore.user_header.is_staff && (
            <p className={resultsCN} onClick={() => printTable(data.id)}>
              Результаты
            </p>
          )}
        </div>
      </div>
    </div>
  );
});

export default Task;
