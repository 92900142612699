import { observer } from 'mobx-react-lite';
import { createCoursesStore } from 'Stores/CreateCourseStore';
import { CreateCourseHead } from './components/CreateCourseHead/CreateCourseHead';
import { LessonPath } from './components/LessonPath/LessonPath';
import { StringPath } from './components/StringPath/StringPath';
import { TaskPath } from './components/TaskPath/TaskPath';
import styles from './createCourseModal.module.scss';

export const CreateCourseModal = observer(() => {
  const { createdCourse } = createCoursesStore;

  return (
    <div
      className={styles.table__bg}
      onClick={() => createCoursesStore.setIsOpenCreateCourseTable(false)}
    >
      <div className={styles.table} onClick={e => e.stopPropagation()}>
        <CreateCourseHead />
        <textarea
          className={styles.textarea}
          value={createdCourse?.detailed_description || ''}
          onChange={event => createCoursesStore.addCourseDescription(event)}
          placeholder={'Описане курса'}
        />
        <LessonPath />
        <StringPath />
        <TaskPath />
      </div>
    </div>
  );
});
