import { observer } from 'mobx-react-lite';
import 'codemirror/lib/codemirror.css';
import 'codemirror/theme/material.css';
import 'codemirror/mode/python/python';
import 'codemirror/mode/javascript/javascript';
import { Controlled as ControlledEditor } from 'react-codemirror2';
import { coursesStore } from 'Stores/CourseStore';
import styles from './Editor.module.scss';
import 'style/scss/app.scss';

const Editor = observer(({ language }: { language: string | undefined }) => {
  function handleChange(editor: object, data: object, value: string) {
    coursesStore.setUserCode(value);
  }
  return (
    <div className={styles.editor}>
      <ControlledEditor
        onBeforeChange={handleChange}
        value={coursesStore.userCode}
        className={styles.editor}
        options={{
          lineWrapping: true,
          lint: true,
          lineNumbers: true,
          mode: language,
          theme: 'material',
        }}
      />
    </div>
  );
});

export default Editor;
