import { observer } from 'mobx-react-lite';
import { type NotificationsI } from 'Interfaces/userInterfaces';
import { Link } from 'react-router-dom';
import icon from 'style/img/notification-icon.svg';
import { BASE_URL } from '../../../../consturl';
import styles from './Notification.module.scss';
const Notification = observer((data: NotificationsI) => {
  return (
    <div className={styles.notification}>
      <div className={styles.logo__notification}>
        <img src={icon} alt="photo" className={styles.not_pic} />
      </div>

      <div className={styles.text__notification}>
        <h3 className={styles.title}>{data.title}</h3>
        <p className={styles.description__notification}>{data.text}</p>

        {data.type !== 'tech_work' && data.type === 'achievement' && 'rank' ? (
          <Link to="/profile" className={styles.go__notification__button}>
            Перейти
          </Link>
        ) : (
          <Link to="/" className={styles.go__notification__button}>
            Перейти
          </Link>
        )}
      </div>
    </div>
  );
});

export default Notification;
