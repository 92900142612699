import { observer } from 'mobx-react-lite';
import {
  NAVIGATE_HEADER_USER,
  NAVIGATE_HEADER_ADMIN,
} from 'components/Header/constant';
import CartNavigate from 'components/Header/components/NavigateHeader/components/CartNavigate/CartNavigate';
import { type CartNavigateI } from 'Interfaces/helpInterfaces';
import { userStore } from 'Stores/UserStore';
import cn from 'classnames';
import styles from './NavigateHeader.module.scss';

const NavigateHeader = observer(() => {
  const { modalPlatformVis } = userStore;

  return (
    <div
      className={cn(styles.navigate__header, {
        [styles.open__modal]: modalPlatformVis,
      })}
    >
      {userStore.user_header.is_staff
        ? NAVIGATE_HEADER_ADMIN.map((cartNavigate: CartNavigateI, index) => (
          <CartNavigate {...cartNavigate} key={index} />
        ))
        : NAVIGATE_HEADER_USER.map((cartNavigate: CartNavigateI, index) => (
          <CartNavigate {...cartNavigate} key={index} />
        ))}
    </div>
  );
});

export default NavigateHeader;
